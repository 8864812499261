.hero-banner {
  max-width: 900px;
  padding: 0 20px;
  margin: 0 auto 45px;
  @include pie-clearfix;

  @include breakpoint(560px) {
  	margin: 0 auto 85px; 

  	@include breakpoint($desk) {
  		margin: 0 auto;
 		}
  }

	.image-wrapper {
		position: relative;
    text-align: center;

    @include breakpoint($desk) {
	    padding: 0 20px;
	  }

		// &:after {
		// 	content: '';
		// 	background-image: url('../images/xmas_sale.png');
	 //    display: block;
	 //    height: 100%;
	 //    position: absolute;
	 //    background-repeat: no-repeat;
	 //    top: 20%;
	 //    right: -18px;
	 //    width: 100px;
	 //    background-size: 100px;

	 //    @include breakpoint($tab) {
		//     top: 25%;
		//     width: 200px;
		//     background-size: 200px;

		//     @include breakpoint($desk) {
		// 	    right: -10%;
		// 	  }
		//   }
		// }

    .hover-content {
    	display: none;

    	@include breakpoint($desk) {
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%,-50%);
		    display: none;
		  }

	    .title {
	    	padding-bottom: 60px;
	    	font-weight: bold;
				font-size: 30px;
	    }

	    .cta {
		    position: relative;
		    display: inline-block;
		    padding-right: 35px;

				&:before {
					@extend .font-icon;
					@extend .icon-submit-arrow;
					top: 50%;
			    position: absolute;
			    right: 0;
			    transform: translatey(-50%);
				}

	    	a {
		    	font-weight: bold;
					font-size: 20px;
				}
	    }
    }

    .body-wrapper {
	    .body {
	    	position: relative;
    		padding-top: 20px;
		    z-index: 1;
		    margin: 0 auto;

		    @include breakpoint(560px) {
    	    max-width: 480px;
    			font-size: 30px;
			  }

	    // 	&:before {
	    // 		@extend .font-icon;
	    // 		@extend .icon-hover-link;
			  //   color: $background;
	    //     position: absolute;
			  //   z-index: -1;
			  //   font-weight: 700;
			  //   font-size: 130px;
			  //   top: -57px;
			  //   left: 20%;
					
					// @include breakpoint(560px) {
				 //    font-size: 285px;
    	// 			top: -140px;

					// 	@include breakpoint(1165px) {
					//         font-size: 288px;
					// 	    top: -70%;
					// 	    left: -16%;
					//   }
				 //  }
	    // 	}
	    }
    }
	}

	&:hover {
		@include breakpoint($desk) {
			.image-wrapper a.overlay {
		    position:  relative;
		    display: block;

		    &:after {
		      content:'';
		      width:100%;
		      height:100%;
		      position:absolute;
		      display: block;
		      background: rgba(255, 255, 255, 0.3);
		      top: 0;
		    }
			}


			.hover-content {
				display: block;
				z-index: 1;
			}
		}
	}
}

.front-two-images {
	@include pie-clearfix;

	.node__content {
		@include pie-clearfix;
		position: relative;

		img {
			width: 100%;
			height: auto;
		}
	}

	.left-side {
		display: inline-block;
		position: relative;
		width: 100%;

		@include breakpoint($tab) {
			width: 50%;
			float: left;
			@include breakpoint($desk) {
				width: 55%;
			}
		}
	}

	.right-side {
		display: inline-block;
		width: 100%;
		padding-left: 10%;
		text-align: right;
		padding-right: 10%;

		@include breakpoint($tab) {
			width: 50%;
			padding-right: 0;
			padding-left: 35px;
			@include breakpoint($desk) {
				float: right;
				padding-left: 30px;
				width: 45%;
			}
		}

		img {
			@include breakpoint($tab) {
				max-width: 480px;
			}
		}
	}

	.body-wrapper {
	    position: relative;
	    margin-top: 10px;
	    z-index: 1;
		position: relative;
		clear: both;

	    @include breakpoint(1400px) {
	    	position: absolute;
	    	margin: 0;
	    	padding: 0;
	    	bottom: 0;
	    	left: 60%;
	    	clear: none;
	    	padding: 50px 15px 21px;
	    }

	    .body {
			font-size: 18px;
			line-height: 23px;
			max-width: 340px;
			margin: 0 auto;
			font-weight: 700;
	    text-align: center;
      padding-top: 20px;

		    @include breakpoint($tab) {
	    		font-size: 30px;
	    		line-height: 33px;
	    		margin: 0;
	    		text-align: left;
	    		padding-top: 20px;
	    		max-width: 480px;
	    		margin: 0 auto;
	    		@include breakpoint($desk) {
    	    	max-width: 480px;
	    		}
			}

	   //  	&:before {
	   //  		@extend .font-icon;
	   //  		@extend .icon-hover-link;
			 //    color: $background;
	   //      	position: absolute;
			 //    z-index: -1;
			 //    font-weight: 700;
			 //    font-size: 130px;
		  //       top: 0;
				// left: 0;
				// right: 0;
				// display: block;
				// margin: 0 auto;

				// @include breakpoint($tab) {
				//     font-size: 288px;
				//     bottom: 25px;
				//     top: auto;
				//     left: -150px;
		  //   	}
		  //   }

		    p {
		    	margin: 0;
		    }
	    }
	}
}

.collections {
	padding: 0 20px;
	max-width: 1200px;
	margin: 0 auto 40px;
	@include pie-clearfix;

	.block__title {
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		margin: 30px auto;

		@include breakpoint($tab) {
			font-size: 30px;
			margin: 50px auto;
		}
	}

	.views-row {
		width: 100%;
		float: left;
		padding: 0 20px;
		margin-bottom: 20px;
		text-align: center;

		@include breakpoint($tab) {
			width: 50%;
		}

		.image-wrapper {
	    margin-bottom: 25px;

	    @include breakpoint($tab) {
				margin-bottom: 60px;
			}

			img {
				width: 130px;

				@include breakpoint($tab) {
					max-width: 265px;
				}
			}
		}

		.title {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 15px;

			@include breakpoint($tab) {
				font-size: 25px;
				margin-bottom: 40px;
			}
		}

		.body {
			font-size: 16px;
			line-height: 20px;

			@include breakpoint($tab) {
	      font-size: 20px;
	      line-height: 25px;
	    }
		}
	}
}

.featured-products,
.featured-products-bottom {
  @include pie-clearfix;
  max-width: 600px;
  padding: 0 20px;
  text-align: center;
  margin: 0 auto 15px;

  @include breakpoint($desk) { 
	margin: 0 auto;
	max-width: 1250px;
  }

  .flex-control-nav {
    display: none;
  }

  .flex-direction-nav li {
    position: absolute;
    top: 50%;
  }

  .flex-nav-next {
    right: -8px;

    @include breakpoint($desk) {
      right: -5px;
    }
  }

  .flex-direction-nav .flex-prev {
    left: -25px;
  }

  .flex-direction-nav .flex-next {
    right: 5px;
  }

  .flex-direction-nav a {
    opacity: 1;
    font-size: 0;
  
    @include breakpoint($desk) {
      left: 21px;
    }
  }

  .flex-direction-nav li {
    width: 45px;
    height: 45px;
  }

  .flex-direction-nav .flex-prev {
    //@extend .icon-keyboard_arrow_left;
    @extend .font-icon;
    content: url('../svg/product-right-arrow.svg');
    transform: rotate(180deg);
    width: 45px;
    height: 45px;

  }

  .flex-direction-nav .flex-next {
    content: url('../svg/product-right-arrow.svg');
    @extend .font-icon;
  }

  h2.block__title {
  	margin: 30px auto 15px;

  	@include breakpoint($desk) {
			margin: 50px auto 15px;
		}
  }

  .views-row {
    display: inline-block;
    @include breakpoint($tab) {
		  width: 50%;

		  @include breakpoint($desk) {
		  	width: 33.333%;
		  	padding: 10px 8px;
		  }
		}

		&:first-child {
			@include breakpoint($desk) {
				padding-left: 0;
				padding-right: 16px;
			}
		}

		&:last-child {

			@include breakpoint($desk) {
				padding-right: 0;
				padding-left: 16px;
			}
		}

    &:hover {
			@include breakpoint($desk) {
				.image-wrapper a.overlay {
			    position:  relative;
			    display: block;

			    &:after {
			      content:'';
			      width:100%;
			      height:100%;
			      position:absolute;
			      display: block;
			      background: rgba(255, 255, 255, 0.3);
			      top: 0;
			    }
				}


				.hover-content {
					display: block;
					z-index: 1;
				}
			}
		}
  }

	.image-wrapper {
		position: relative;
    text-align: center;

	  img {
	  	display: block
	  }

    .hover-content {
    	display: none;

    	@include breakpoint($desk) {
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%,-50%);
		    display: none;
		  }

	    .title {
	    	padding-bottom: 60px;
	    	font-weight: bold;
				font-size: 29px;
	    }

	    .cta {
		    position: relative;
		    display: inline-block;
		    padding-right: 35px;

				&:before {
					@extend .font-icon;
					@extend .icon-submit-arrow;
					top: 50%;
			    position: absolute;
			    right: 0;
			    transform: translatey(-50%);
				}

	    	a {
		    	font-weight: bold;
					font-size: 20px;
				}
	    }
    }
	}
}

.featured-products {
	position: relative;
}

.featured-products-bottom {
	margin-bottom: 20px;
	max-width: 1250px;

	.views-row {
		padding: 0;
        margin-bottom: 15px;
        width: 100%;

        @include breakpoint($tab) {
        	width: 50%;

        	@include breakpoint(1242px) {
				width: auto;
			}
		}

		&:first-child,
		&:last-child {
			padding: 0;
		}

		&:nth-child(2n+1) {
			clear: both;
		}
	}


	.views-row-odd,
	.views-row:first-child {
		@include breakpoint($tab) {
			padding-right: 8px;
			float: left;
		}
	}

	.views-row-even,
	.views-row:last-child {

		@include breakpoint($tab) {
			padding-left: 8px;
			float: right;
		}
	}

	.views-row-2,
	.views-row-3 {
		@include breakpoint($tab) {
			margin-top: 100px;

			@include breakpoint(1242px) {
				margin-top: 0;
			}
		}
	}
}

.new-arrivals {
	background: $background;
	padding: 13px 20px 40px;
	text-align: center;

	@include breakpoint($tab) {
		padding: 15px 20px 40px;
	}

	.block__title {
		font-family: $second_font;
		font-size: 33px;
		line-height: 41px;

		@include breakpoint($tab) {
			font-size: 30px;
			line-height: 40px;

			@include breakpoint($desk) {
				font-size: 43px;
				line-height: 50px;
			}
		}
	}

	.block__content {
		.view-header {
			max-width: 520px;
			margin: 13px auto 25px;
			font-size: 13px;
			line-height: 18px;

			@include breakpoint($tab) {
				font-size: 15px;
				line-height: 20px;
				margin: 20px auto 30px;
			}
		}

		.view-content {
			@include pie-clearfix;
			.views-row {
				width: 50%;
				float: left;
				padding: 0 10px;
				margin-bottom: 20px;

				@include breakpoint($tab) {
					margin: 0 20px;
					padding: 0;
					float: none;
					display: inline-block;
					width: auto;
				}

				a {
					line-height: 20px;
					font-size: 15px;
					padding: 7px 28px 8px;
					display: inline-block;
					border: 1px solid $black;
					color: $black;
					width: 100%;
					transition: all .2s ease-in-out;

					@include breakpoint($tab) {
						font-size: 17px;
						width: auto;
					}

					&:hover {
						color: #fff;
						background: $black;
						border-radius: 30px;
					}
				}
			}
		}
	}
}

.philosophy-two-images-with-text,
.philosophy-two-images-with-bold-text {	
	padding: 0 20px;
	background: $black;
	position: relative;

	.node__content {
		max-width: 1195px;
		margin: 0 auto;
	}

    
    .field--name-field-marketing-post-image img {
		max-width: 477px;
    }

    .field--name-field-mkt-background-image img {
		max-width: 773px;
    }
}

.philosophy-two-images-with-text {
	.left-side {
		width: 35%;
		padding-right: 40px;
	}

	.right-side {
		width: 65%;
	}

	.body-wrapper {
		font-weight: bold;
		padding: 50px 0 30px;
		line-height: 18px;
		font-size: 14px;
		max-width: 700px;
		margin: 0 auto;
		text-align: center;
		color: #fff;

		@include breakpoint($tab) {
			padding: 50px 0;
			line-height: 24px;
			font-size: 20px;
		}
	}
}

.philosophy-two-images-with-bold-text {
	padding-bottom: 85px;
	.left-side {
	    width: 64%;
    	padding-right: 40px;
	}

	.right-side {
		width: 36%;
	}

	.body-wrapper {
		font-weight: bold;
		max-width: 700px;
		margin: 0 auto;
		text-align: center;
		color: #fff;
	    padding: 50px 0;
	    line-height: 23px;
	    font-size: 19px;

	    @include breakpoint($tab) {
			padding: 100px 0;
			line-height: 35px;
			font-size: 30px;
		}
	}
}

.node--marketing-post {
	a {
		color: $black;
		font-size: 14px;

		@include breakpoint($tab) {
			font-size: 15px;

			@include breakpoint($desk) {
				font-size: 19px;
			}
		}

	}
	.left-side {
		display: inline-block;
	}

	.right-side {
		float: right;
		display: inline-block;
	}
}











