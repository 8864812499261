* {
	box-sizing: border-box;
}

::selection {
  color: #fff;
  background: $grey;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $grey;
}

body {
	font-family: $default_font;
	color: $black;
	font-size: 15px;
	font-weight: 300;
}

h1 {
	text-align: center;
	line-height: 42px;
	font-size: 23px;

	@include breakpoint($tab) {
		font-size: 34px;
	}
}

h2.block__title {
	font-size: 18px;
	text-align: center;
	font-weight: bold;
    margin: 30px auto;

    @include breakpoint($tab) {
		margin: 50px auto;
		font-size: 30px;
    }
}

// --------------- Throbber ----------------

.ajax-progress {
  position: fixed;
  z-index: 99999;
  background: rgba(0,0,0,0.35);
  width: 100%;
  height: 100%;
  top:  0;
  left: 0;

  .throbber {
  	position: fixed;
    background: none;
    @extend .font-icon;
    @extend .icon-hover-link;
    color: $background;
    top: 50%;
    left: 48%;
    transform: translate(-50%,-50%);
    font-size: 50px;

    @include breakpoint($tab) {
		font-size: 70px;
	}

    &:before {
      -webkit-animation: pin 2s infinite linear;
      animation: spin 2s infinite linear;
    }
  }
}

.checkout-processing,
.flag-waiting .flag-throbber {
  position: fixed;
  z-index: 99999;
  background: rgba(0,0,0,0.35);
  width: 100%;
  height: 100%;
  top:  0;
  left: 0;
  padding: 25px;
  @extend .font-icon;
  @extend .icon-hover-link;
  color: #fffx;
  font-size: 70px;

  &:before {
  	position: fixed;
  	top: 50%;
  left: 48%;
  transform: translateX(-50%) translateY(-50%);
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
  }
}

.loader-wrapper {
  position: fixed;
  background: rgba(0,0,0,0.35);
  z-index: 9999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  
  .site-logo {
		position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .loader {
    position: fixed;
    background: none;
    @extend .font-icon;
    @extend .icon-hover-link;
    color: #fffx;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 50px;

    @include breakpoint($tab) {
		font-size: 70px;
	}

    &:before {
      -webkit-animation: spin 2s infinite linear;
      animation: spin 2s infinite linear;
    }
  }
}

a {
	text-decoration: none;
	font-weight: 400;
	transition: all 0.2s;

	&:hover {
		transition: all 0.2s;
		text-decoration: none;
	}
}

img {
	width: 100%;
	max-width: 100%;
	height: auto;
}

b,
strong {
	font-weight: bold;
}

u {
	font-weight: normal;
}

ul {
	padding: 0;
	margin: 0;
}

p {
	margin-bottom: 10px;
}

.container {
	width: 100%;
	//max-width: $mob;
	margin: 0 auto;
	padding: 0 20px;

	@include breakpoint($tab) {
		max-width: $tab;
	}

	@include breakpoint($desk) {
		padding: 0 35px;
		max-width: 100%;
	}

	@include breakpoint($wide) {
		max-width: $wide;
	}
}

.container-tab {
	width: 100%;
	margin: 0 auto;

	@include breakpoint($tab) {
		padding: 0 20px;
		max-width: $tab;
	}

	@include breakpoint($desk) {
		max-width: 100%;
	}

	@include breakpoint($wide) {
		max-width: $wide;
	}
}

.clearfix {
	clear: both;
}

.tab-and-above {
	display: none;

	@include breakpoint($tab) {
		display: block;
	}
}

.till-mobile {
	@include breakpoint($tab) {
		display: none;
	}
}

.only-desk {
	display: none; 

	@include breakpoint($desk) {
		display: block;
	}
}

.mobile-and-tab {
	@include breakpoint($desk) {
		display: none;
	}
}

// --------------- Scrollbar ----------------

.mCustomScrollbar {
    max-height: 200px;
    overflow: scroll;
}

.mCSB_inside > .mCSB_container {
  margin: 0;

  @include breakpoint($tab) {
    padding-right: 10px;
  }
}

.mCSB_scrollTools {
  right: 10px;
}

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: $grey_icon;
}

.mCSB_scrollTools .mCSB_draggerRail {
  background: $border;
	width: 1px;
}

