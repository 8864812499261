.term-info {

	.term-info-highlighted-wrapper {
		@include pie-clearfix;
		background: $black;

		.term-info-wrapper {
			@include pie-clearfix;
			padding: 20px;

			@include breakpoint($tab) {
				padding: 37px 0;
			}

			.term-info-description {
				width: 100%;
				max-width: 658px;
				margin: 0 auto;
				color: #fff;
				line-height: 17px;
				font-size: 14px;
				text-align: center;
				margin: 0 auto;
				line-height: normal;

				@include breakpoint($tab) {
					font-size: 20px;
				}
			}

			.term-info-title {
				display: inline-block;
	    	color: #fff;
				position: relative;
				text-align: center;
				margin: 5px auto 0;
	    	width: 100%;
	    	font-size: 20px;
	    	font-weight: bold;

	    	@include breakpoint($tab) {
	    		margin: 45px auto 0;
	    	}

	    	h1 {
	    		font-size: 19px;

	    		@include breakpoint($tab) {
		    		font-size: 20px;
		    	}
	    	}
			}
		}
	}
}

.term-info.open {
	.term-designer-title {
		position: relative;

		&:before {
			content: url('../svg/up-arrow.svg');
	    right: 10px;
	    width: 20px;
	    height: 5px;
		}
	}

  .term-info-highlighted-wrapper .term-info-wrapper .term-info-name {
		position: relative;

		&:before {
			content: url('../svg/up-arrow.svg');
	    right: 10px;
	    width: 20px;
	    height: 5px;
		}
	}

	.term-info-highlighted-wrapper .term-info-wrapper .designer-content-wrapper {
		display: block;
		padding-top: 20px;
	}

	.taxonomy-term-children {
		display: block;
	}
}


#rocket-path-elastic-page-wrapper {
	@include pie-clearfix;
}

.rocket-path-elastic-header,
.rocket-path-elastic-results {
	width: 100%;

	rocket-path-elastic-results-empty {
		text-align: center;
		padding: 30px 0;
	}

	.products-grid {
		border-bottom: 1px solid $background;

		@include breakpoint($tab) {
			border: 0;
		}
	}

	.products-grid > * {
 		//border-top: 1px solid $background;
 		position: relative;
 		float: left;
    margin-bottom: 30px;

 		@include breakpoint($tab) {
			margin-bottom: 50px;
		}

 		// &:nth-child(n) {
	 	// 	&:before {
	 	// 		width: 1px;
	 	// 		left: 0;
	 	// 		height: 100%;
	 	// 		top: 0;
	 	// 		position: absolute;
	 	// 		display: block;
	 	// 		background: $background;
	 	// 		content: '';
	 	// 	}
	 	// }

	 	&:nth-child(2n+1) {
	 		clear: both;

	 		// &:before {
	 		// 	left: auto;
	 		// 	right: -1px;
	 		// }

	 		@include breakpoint($desk) {
		 		clear: none;
		 	}
	 	}

	 	&:nth-child(4n+1) {
	 		@include breakpoint($desk) {
		 		clear: both;
		 	}
	 	}

		@include breakpoint($tab) {
			border: 0;

			&:nth-child(n) {
	 			&:before {
	 				display: none;
	 			}
	 		}
		}
	}
}

.rocket-path-elastic-footer,
.rocket-path-elastic-header {
	clear: both;
	@include pie-clearfix;
	font-size: 12px;
	padding: 5px 0;
	margin-bottom: 5px;

	@include breakpoint($tab) {
		// border-top: 1px solid $background;
		padding: 15px 0 20px 30px;
		margin: 0;
		border: 0;
	}

	.rocket-path-elastic-total {
		font-size: 12px;
		width: 100%;
		text-align: right;
		line-height: 19px;

		@include breakpoint($tab) {
			text-align: left;
			width: auto;
			float: right;
			margin-right: 50px;
		}

		a {
			color: $black;
			text-decoration: underline;
		}
	}

	.rocket-path-elastic-sort {
		float: right;
		position: relative;

		label {
			line-height: 21px;
	    font-size: 15px;
	    font-weight: 500;
	    padding: 10px 40px 10px 55px;
	    color: #000000;
	    display: block;
	    position: relative;
	    cursor: pointer;

	    &:before {
				@extend .font-icon;
				content: url('../svg/down-arrow.svg');
		    display: block;
	      position: absolute;
	      right: 0;
	      color: $black;
			}
		}


		ul {
			min-width: 175px;
			z-index: 10;
			padding: 0;
			margin: 0;
			border-top: 1px solid $black;
			border-bottom: 1px solid $black;
			position: absolute;
			background: #fff;
			display: none;
			padding: 15px 20px;

			li {
				list-style: none;
				width: 100%;

				a {
					display: block;
					color: $black;
					font-weight: 300;
			    line-height: 28px;
			    font-size: 15px;
				}
			}
		}
	}

	.rocket-path-elastic-sort.open-sort ul {
		display: block;
	}

	.rocket-path-elastic-pager {
		float: right;

		label {
			color: $black;
			margin: 0;
			margin-right: 8px;
			font-weight: normal;
			font-weight: 300;
			line-height: 17px;
			font-size: 12px;
		}

		ul {
			display: inline-block;
			margin: 0;
			padding: 0;

			li {
				list-style-type: none;
				list-style-image: none;
				display: inline-block;
				margin: 0 5px;

				@include breakpoint($tab) {
					margin: 0 3px;
				}

				a {
					font-size: 20px;

					@include breakpoint($tab) {
						font-size: 16px;
					}
				}

				&.active-page a {
					color: $black;
					font-weight: bold;
				}

				&.previous-page,
				&.next-page {
			    display: none;
				}

				a {
					color: $black;
					line-height: 20px;
					font-size: 14px;
			    margin: 8px;
	        font-weight: 100;
				}
			}
		}
	}
}

.mobile-instant-search-results {
	.rocket-path-elastic-header {
		display: none;
	}
}

.rocket-path-elastic-facets-active {
	float: left;
  padding-left: 22px;
  width: 85%;
  
  @include breakpoint($tab) {
	  width: 30%;
	  padding-left: 30px;

    @include breakpoint($desk) {
    	width: auto;
    }
	}

	ul {
		margin: 0;
		border: 0;
    padding: 5px 0;

		li {
			list-style-type: none;
			list-style-image: none;
			line-height: 20px;
			position: relative;
			font-size: 12px;
			color: $grey;
			display: inline-block;
			margin-right: 15px;
			padding: 5px 22px 5px 9px;

		.remove-facet {
		    display: block;
		    text-decoration: none;
		    width: 11px;
		    height: 11px;
		    position: absolute;
		    top: 7px;
		    right: 7px;
		    cursor: pointer;

        .line {
          display: block;
          width: 100%;
          height: 1px;
          background: $grey;
          position: absolute;
          top: 7px;

          &:first-child {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);

            transform: rotate(45deg);
          }

          &:last-child {
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
		}
	}
}

.rocket_path-elastic-total-active-clear-button {
	display: inline-block;
	float: left;
	padding: 13px 0;

	a {
		font-size: 15px;
		color: $grey;
		position: relative;
		padding-right: 24px;
	}

	.remove-facet {
    display: block;
    text-decoration: none;
    width: 11px;
    height: 11px;
    position: absolute;
    top: 2px;
    right: 7px;
    cursor: pointer;

    .line {
      display: block;
      width: 100%;
      height: 1px;
      background: $grey;
      position: absolute;
      top: 7px;

      &:first-child {
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);

        transform: rotate(45deg);
      }

      &:last-child {
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}


.rocket-path-elastic-facets {
	@include pie-clearfix;
  display: inline-block;
  float: left;
  width: 100%;

  @include breakpoint($tab) {
	  width: 33.333%;
	}

  .rocket-path-elastic-facets-inner {
		display: block;
  }

	.rocket-path-elastic-facets-label {
		float: left;
    padding: 5px 10px;
    color: $black;
    border: 1px solid $border;
    font-weight: 300;
    line-height: 18px;
    font-size: 13px;
    width: 45%;
    position: relative;

		&:before {
	    content: '';
	    font-size: 20px;
	    top: 4px;
	    right: 30px;
	    position: absolute;
	    line-height: 19px;
	    width: 1px;
	    height: 20px;
	    display: block;
	    background: $border;
		}

		&:after {
			content: url('../svg/triangle-down.svg');
	    font-size: 20px;
	    top: 50%;
	    right: 10px;
	    position: absolute;
	    transform: translatey(-50%);
	    line-height: 3px;
		}
	}

	.rocket-path-elastic-facets-term-title {
		line-height: 40px;
		font-size: 16px;
		padding: 0 20px;
	}

	&.mobile-facets .rocket-path-elastic-facets-inner {
	// .rocket-path-elastic-facets-inner {
		position: fixed;
		display: block;
		height: 100%;
		z-index: 115;
		width: 100%;
		top: 0;
		left: 0;
		background: #fff;
    overflow: scroll;
    padding-bottom: 100px;

		.rocket-path-elastic-facets-active {
			background: #F5F5F5;
			ul {
				padding: 0;

				li {
					border: 0;
					width: 100%;
					padding: 0;

					.remove-facet {
						width: 10px;
						height: 10px;
					}
				}
			}
		}

		.rocket-path-elastic-facets-mob-header {
			background: #fff;

			.rocket-path-elastic-facets-active {
				color: $grey;
			}

			.rocket-path-elastic-facets-title {
				width: 100%;
				text-align: center;
				position: relative;
				font-size: 16px;
				color: $black;
				line-height: 57px;

				.close-filters {
					position: absolute;
					top: 50%;
					right: 20px;
					font-size: 0;
					color: $grey;
			    line-height: 0;
    			transform: translatey(-50%);

					&:before {
						content: url('../svg/close.svg');
						display: inline-block;
					}
				}
			}
		}

		.facet-wrapper {
			width: 100%;
			float: none;
			margin: 0;

			&.facet-merge {
				.facet-content {
					margin-bottom: 0;
					//border-bottom: 1px solid $border;
				}
			}

			&.facet-size {
				.facet-ul-wrapper {
					max-height: none !important;

					ul {

						@include pie-clearfix;
						padding: 10px 20px;

						li {
							margin: 4px;

							a {
								padding: 0 10px;
							}
						}
					}
				}
			}

			.facet-title {
				padding: 15px 30px;
				width: 100%;
				margin: 0;
				background: #fff;
				color: $black;
				text-align: center;
				border-bottom: 0;
				font-weight: 500;
				line-height: 21px;
				font-size: 15px;

				&:before {
					display: none;
				}
			}

			.facet-content > .facet-ul-wrapper {
				overflow-y: auto;
			}

			ul {
				background: #fff;

				li {
					margin: 0;

					&.checked {
						background: $background;

					 & > a {
							&:after {
				    	 content: url('../svg/check.svg');
				       display: inline-block;
					    position: absolute;
					    right: 30px;
						  }
						}
					}
				}

				a {
					padding: 0 20px;
					font-size: 14px;
					line-height: 39px;
					//border-bottom: 1px solid #D9D9D9;
					display: block;
					width: 100%;
					position: relative;
				}
			}

			&.open-facet .facet-ul-wrapper {
				display: block;
			}
		}
	}

	.facet-wrapper.facet-price {
		display: none;
	}

	.facet-wrapper {
		//clear: both;
		display: inline-block;
		float: left;
		//width: 100%;
		//margin-bottom: 10px;

		&.facet-merge {
			.facet-content {
				border-top: 1px solid $background;
				border-bottom: 1px solid $background;
				margin-bottom: 5px;

				&:nth-child(2) {
					border-top: 0;
				}

				&:last-child {
					border-bottom: 0;
				}
			}
		}

		.facet-content {
			display: block;
			margin-left: 28px;

			@include breakpoint($desk) {
				margin-left: 55px;
			}

			.facet-ul-wrapper {
				display: none;
			}
		}

		ul {
			margin: 0;
			padding: 0;

			li {
				line-height: 18px;
				list-style-type: none;
				list-style-image: none;
				font-size: 14px;
				// margin-bottom: 5px;
				// margin-top: 2px;

				ul {
					padding-top: 5px;
					padding-left: 15px;
					position: relative;
				}

				a {
				    color: $black;
				    font-weight: 300;
					line-height: 28px;
					font-size: 15px;
				   padding: 0 10px;

				    &:before {
				    	content: '';
				        display: inline-block;
				        width: 15px;
				        height: 15px;
				    	border: 1px solid $black;
			    	    margin-right: 15px;
			    	    position: relative;
			    	    top: 4px;
				    }
				}

				&.checked > a {
					&:after {
				    	    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    border: 1px solid #000000;
    background: #000000;
    position: absolute;
    left: 13px;
    top: 9px;
				    }
				}

				&.zero-results a {
					cursor: default;
					color: #C4C4C4;
				}

				.facet-count {
					margin-left: 2px;
				}
			}
		}
	}

	.facet-wrapper.flipster_size,
	.facet-wrapper.jewel_size,
	.facet-wrapper.kids_sizes,
	.facet-wrapper.sandals_size,
	.facet-wrapper.size,
	.facet-wrapper.sml_size {
		ul li {
		    display: inline-block;
		    margin-right: 5px;
		    margin-bottom: 5px;
		    float: left;

		    a {
			    color: $black;
			    font-size: 14px;
			    line-height: 17px;
			    border: 1px solid $black;
			    padding: 5px 10px;
			}

		    &.checked {
				background: $black;
				color: #fff;
		    }
		}
	}

	.facet-title {
		line-height: 21px;
		font-size: 15px;
		// border-bottom: 1px solid $border;
		// border-top: 1px solid $border;
		font-weight: 500;
		padding: 10px 40px 10px 30px;
		color: $black;
		display: block;
		position: relative;
		cursor: pointer;
    
    @include breakpoint($desk) {
			padding: 10px 40px 10px 55px;
		}

		&:before {
			@extend .font-icon;
			content: url('../svg/down-arrow.svg');
	    display: block;
      position: absolute;
      right: 0;
      color: $black;
		}
	}

	// .rocket-path-elastic-facets-inner {
	// 	border-top: 1px solid $border;
	// }

	.facet-wrapper.close {
		margin: 0;

		.facet-ul-wrapper {
			display: block;
	    position: absolute;
	    border-top: 1px solid #000;
	    border-bottom: 1px solid #000;
	    z-index: 1;
	    background: #fff;
      width: 176px;
    	padding: 10px 20px;
		}

		.facet-title {
			position: relative;
			border-top: none;
			margin-bottom: 20px;

			&:before {
				transform: translateY(-50%);
			}
		}
	}

	.facet-wrapper.close {
		margin-bottom: 0;

		.facet-title {
			position: relative;
			cursor: pointer;
			margin: 0;
	    border-bottom: 0;

			&:before {
				@extend .font-icon;
				content: url('../svg/down-arrow.svg');
	    	display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        font-size: 20px;
		    font-weight: 100;
		    width: 23px;
		    height: 30px;
        color: $grey-icon;
			}
		}
	}
}


body.with-overlay {
	overflow: hidden;
}

//Search page
.search-overlay {
  display: none;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 1000;

  &.show {
    display: block;
  }

  .rocket-path-elastic-instant-search-wrapper {
    padding: 27px 50px 0;

		.rocket-path-elastic-pager {
			display: none;
		}

    .rocket-path-elastic-instant-logo {
      width: 30%;
      display: inline-block;
      float: left;
      margin-top: 4px;

      @include breakpoint($desk) {
        width: 240px;
        margin-right: 75px;
        margin-top: 0;
      }

      img {
        width: 100%;
        height: auto;
        max-width: 306px;
      }
    }

    .search-input-wrapper {
      width: 50%;
      display: inline;
      position: relative;
      float: left;
      margin: 0 50px;
      border-bottom: 1px solid $black;

      @include breakpoint($desk) {
        margin: 0;
      }

      @include breakpoint($wide) {
        max-width: 800px;
        margin-top: 5px;
      }

      .form-text {
        background: #fff;
        padding: 7px 0 15px;
        line-height: 16px;
        font-size: 14px;
        border: 0;
      }

      &:before {
        @extend .font-icon;
        // @extend .icon-search;
        color: $black;
        font-size: 20px;
        top: 15px;
        right: 0;
        position: absolute;
        line-height: 0;
      }
    }

    .rocket-path-elastic-instant-search-close {
      float: right;
      display: inline-block;
      position: relative;
      font-size: 0;
      line-height: 0px;
      top: 10px;

      &:before {
        @extend .font-icon;
        // @extend .icon-cross;
        color: $black;
        font-size: 30px;
        top: 15px;
        right: 0;
        position: absolute;
        line-height: 0;
      }
    }

    .rocket-path-elastic-instant-search-results .rocket-path-elastic-results {
      float: none;
      clear: both;
      margin: 0 auto;
      max-width: 1020px;
      padding: 20px;
      width: 100%;

      .products-grid {
        overflow: hidden;
        @include pie-clearfix;
        border: 1px solid $background;
        border-top: 0;

        .grid-1-4 {
          border-top: 1px solid $background;
          margin-bottom: 50px;
          position: relative;

          &:after {
            width: 1px;
            height: 150%;
            top: 0;
            right: -1px;
            content: '';
            background: $background;
            display: block;
            position: absolute;
          }

          .image-wrapper {
            border: 0;
          }
        }
      }
    }
  }
}

.rocket-path-elastic-facets .facet-wrapper {
	&.facet-size {
		.facet-ul-wrapper {
			max-height: none;
		}

		ul {
			@include pie-clearfix;

			li {
				// display: inline-block;
				// float: left;
				text-align: center;

				a {
					display: block;
					width: 100%;
					height: 100%;
			    text-align: left;
				}

				&.zero-results a {
					cursor: default;
					color: #C4C4C4;
					border: 1px solid #C4C4C4;
				}

				&:nth-child(3n+1) {
					margin-left: 0;
					margin-right: 4px;
				}
				&:nth-child(3n) {
					margin-left: 4px;
					margin-right: 0;
				}
			}
		}
	}
}


.rocket-path-elastic-footer {
	// border-top: 1px solid $border;
  padding: 15px 0;

  @include breakpoint($tab) {
	  padding: 15px 0 15px 30px;
	}

	.rocket-path-elastic-footer-wrapper {
		text-align: center;

		@include breakpoint($tab) {
			width: 75%;
			float: right;
		}

		.rocket-path-elastic-pager {
			float: none;
			display: inline-block;

			@include breakpoint($tab) {
		    width: 66.666%;
	    	text-align: left;
	    }

    	label {

    		@include breakpoint($tab) {
			    width: 50%;
			    display: inline-block;
			    text-align: left;
			  }
    	}
		}
	}
}

