textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
	border: 0;
	background: none;
	background: #fff;
	color: $black;
	font-weight: 300;
	padding: 7px 20px 10px;
	font-size: 15px;
	line-height: 20px;

	&:focus {
		outline: none;
	}
}

textarea {
	resize: none;
}

input[type="text"],
input[type="email"],
input[type="password"] {
	max-width: 345px;
	width: 100%;
}

input[type="submit"],
button {
	background: $black;
	color: #fff;
	padding: 7px 38px 8px;
	border: 1px solid $black;
	font-size: 16px;
	line-height: 20px;
	cursor: pointer;
	border-radius: 0;

	&:focus {
		outline: none;
	}

	&:hover {
		border: 1px solid $background;
		// background: repeating-linear-gradient(45deg, #fff, #fff 0, #CCFBF5 15px, #fff 15px);
        color: #fff;
        background: $background;
	}
}

input:-webkit-autofill, 
textarea:-webkit-autofill, 
select:-webkit-autofill {
	background-color: $background !important;
    -webkit-box-shadow: 0 0 0 1000px $background inset !important;
}

.selector {
  background: #fff;
  font-size: 14px;
  padding: 10px 40px 10px 15px;
  width: 100%;
  resize: none;
  text-align: left;
  position: relative;
  cursor: pointer;
  max-width: 100%;

  &:before {
    @extend .font-icon;
    content: url('../svg/down-arrow.svg');
    font-size: 10px;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translatey(-50%);
  }

  select {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.form-item {
	margin-bottom: 20px;

	label {
		margin-bottom: 4px;
	}

	& > .description {
		margin-top: 5px;
	}
}


input[type="checkbox"],
input[type="radio"] {
	display: none;
}

input[type="checkbox"] + label {
	position: relative;
	display: block;
	padding-left: 20px;

	&:before {
		width: 15px;
    height: 15px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: block;
    content: "";
    border: 1px solid $black;
    position: absolute;
    top: 0;
    left: 0;
	}

	&:after {
    width: 9px;
    height: 9px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: none;
    content: "";
    background: $black;
    position: absolute;
    top: 3px;
    left: 3px;
    border: 1px solid $black;
	}
}

input[type="checkbox"]:checked + label:after {
	display: block;
}

input[type="radio"] + label {
	position: relative;
    display: block;
    padding-left: 20px;

    &:before {
        width: 15px;
    height: 15px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: block;
    content: "";
    border: 1px solid $black;
    position: absolute;
    top: 0;
    left: 0;
    }

    &:after {
    width: 9px;
    height: 9px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: none;
    content: "";
    background: $black;
    position: absolute;
    top: 3px;
    left: 3px;
    border: 1px solid $black;
    }
}

input[type="radio"]:checked + label:after {
	display: block;
}

// .marker, 
// .form-required {
//     color: $background;
// }

.resizable-textarea .grippie {
    height: 9px;
    background: $background url(/sites/all/themes/contrib/omega/omega/images/misc/grippie.png?1382488163) no-repeat center 2px;
    border: 1px solid $background;
    border-top-width: 0;
    border-radius: 6px;
    cursor: s-resize;
    overflow: hidden;
    position: relative;
    top: -5px;
}