// Simple dropdown menu
.tb-megamenu {
  background: transparent;

  .dropdown-menu {
    border: 0;
    box-shadow: none;
  }

  .mega > .mega-dropdown-menu {
    box-shadow: none;
  }

  .nav {
    display: table;
    //margin: 0 auto;

    & > li {
      text-align: left;
      padding: 11px 9px;

     @include breakpoint(1200px) {
       padding: 11px 20px;
     }

      & > a {
        border: 0;
        color: $black;
        font-size: 16px;
        line-height: normal;
        font-weight: bold;
        padding: 0;

        &.animating,
        &.open {
          background: $background;
        }
      }
    }
  }

  .nav li.dropdown.active > .dropdown-toggle,
  .nav li.dropdown.open.active > .dropdown-toggle,
  .nav > li.dropdown.open.active > a:hover,
  .nav > .active > a,
  .nav > .active > a:hover,
  .nav > .active > a:focus {
    background: $background;
    color: $black;
    border-color: transparent;
  }

  .nav > .active > a,
  .nav .open > a {
    background: transparent;

    &:hover,
    &:focus {
      background: transparent;
    }
  }

  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: transparent;
  }

  .nav > li > a:hover,
  .nav > li > a:focus {
    color: $black;
    background: transparent;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -2px;
      background: $black;
      display: block;
      z-index: -1;
      left: 0;
    }
  }

  .nav > li.open,
  .nav > li.active.active-trail {
    & > a {
      z-index: 1100;
      position: relative;

      &.dropdown-toggle {
        background-color: transparent !important;
        color: $black !important;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: -2px;
          background: $black;
          display: block;
          z-index: -1;
          left: 0;
        }
      }
    }
  }

  .nav li.dropdown > .dropdown-toggle .caret {
    display: none;
  }

  .dropdown-menu .active > a,
  .dropdown-menu .active > a:hover {
    color: $black;
    text-decoration: none;
    outline: 0;
    background-color: $background;
    background-image: none;
  }

  .dropdown-menu li > a {
    border-top: 0;
  }
}

//Main menu desktop
.ucapparel-main-menu {
  display: none;

  @include breakpoint(1000px) {
    display: block;
    position: relative;
  }

  .tb-megamenu .nav li.red > .dropdown-toggle {
    color: $red;

    &:hover {
      color: $red !important;
    }
  }

  .tb-megamenu .nav > li.active > a {
    font-weight: bold;
  }
  
  .tb-megamenu,
  .tb-megamenu .nav li.dropdown {
    position: inherit;
  }

  .tb-megamenu .mega-nav .mega-group > .mega-group-title:hover,
  .tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title:hover,
  .tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title:hover,
  .tb-megamenu .mega-nav .mega-group > .mega-group-title:active,
  .tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title:active,
  .tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title:active,
  .tb-megamenu .mega-nav .mega-group > .mega-group-title:focus,
  .tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title:focus,
  .tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title:focus {
    color: #fff;
    background: $black;
    font-weight: bold;
  }

  .tb-megamenu .nav li.dropdown.red > .dropdown-toggle,
  .tb-megamenu .nav li.dropdown.open.red > .dropdown-toggle,
  .tb-megamenu .nav > li.dropdown.open.red > a:hover,
  .tb-megamenu .nav > li.red > a:hover,
  .tb-megamenu .nav > li.red > a:focus {
    color: $red;
  }

  .tb-megamenu .row-fluid [class*="span"] {
    min-height: 27px;
  }

  .tb-megamenu .mega-nav .mega-group > .mega-group-title,
  .tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title,
  .tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title {
    pointer-events: none;
    padding: 0 0 0 60px;
  }

  .tb-megamenu .mega-group-ct li a:hover {
    font-weight: bold;
  }

  .tb-megamenu .mega-nav > li.level-2 {
    & > a {
      font-weight: bold;
      font-size: 16px;
    } 
  }

  .mega > .tb-megamenu-submenu.mega-dropdown-menu {
    @include pie-clearfix;
    width: 100% !important;
    max-width: 300px !important;
    position: absolute;
    top: 100%;
    z-index: 9999;
    margin-top: 5px;

    & > .mega-dropdown-inner {
      background: transparent;
      position: relative;
      border: 1px solid $black;
      //padding-left: 60px;
    }

    .mega-inner {
      padding: 0;

      a {
        color: $black;
        font-size: 14px;
        padding: 0;
        padding-left: 60px;
        line-height: 27px;

        &.bold {
          font-weight: bold;
        }

        &:hover {
          background: $black;
          color: #fff;
        }
      }

      li.red a {
        color: $red;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}

.front-right-menu {
  display: inline-block;
  float: right;
  margin: 13px 0 0 15px;
  display: none;

  @include breakpoint($desk) { 
    display: block;

    @include breakpoint(1200px) {
      margin: 13px 0 0 20px;
    }
  }

  ul.menu {
    font-weight: bold;
    line-height: normal;
    font-size: 18px;

    li {
      list-style: none;

      a {
        font-weight: bold;
        line-height: normal;
        font-size: 18px;
        color: $black;
      }
    }
  }
}

.header-left .mobile-main-menu {
  display: none;
  @include breakpoint($desk) {
    display: block;
  }

  ul {
    display: table;

    li.red > a {
      color: $red !important;

      &:before {
        background: $red !important;
      }
    }

    li {
      list-style: none;
      padding: 11px 20px;
      display: inline-block;

      a {
        font-size: 16px;
        line-height: normal;
        font-weight: 700;
        position: relative;
      }

      &:hover a:before,
      &:focus a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -2px;
        background: #000;
        display: block;
        z-index: -1;
        left: 0;
      }

      &.expanded {
        ul {
          display: none;
          width: 100% !important;
          max-width: 300px !important;
          position: absolute;
          top: 100%;
          z-index: 9999;
          margin-top: 5px;
          background: transparent;
          border: 1px solid #000;

          li {
            display: block;
            float: none;
            list-style: none;
            margin: 0;
            padding: 0;

            a {
              font-size: 14px;
              padding: 0;
              padding-left: 60px;
              line-height: 27px;
              display: block;

              &:hover {
                background: #000;
                color: #fff;
              }
              
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.menu-block-hover .header-left .mobile-main-menu ul li.expanded ul {
  display: block;
}

.l-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: $black;
  padding-top: 125px;
  padding-bottom: 50px;
  overflow: scroll;
  display: none;

  @include breakpoint($tab) {
    padding-top: 170px;
  }
  
  &:before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    content: '';
    background: #fff;
    //border-bottom: 1px solid $border;
    height: 120px;

    @include breakpoint($tab) {
      height: 130px;
    }
  }

  .open-menu-mobile & {
    display: block;
  }

  .user-block {
    display: block;
    float: none;
    text-align: center;

    a.user-button {
      color: #fff;

      &:before {
        display: none;
      }
    }
  }

  .front-right-menu {
    display: block;
    float: none;
    position: relative;
    margin: 0;

    ul.menu {
      text-align: center;
      line-height: 40px;

      a {
        color: #fff;
      }
    }
  }

  .block--search-form {
    display: block;
    float: none;
    text-align: center;
    position: relative;
    margin: 10px 0 0;

    label.element-invisible {
      margin: 0;
      color: #fff;
    }
  }

  a.mobile-menu-close {
    display: block;
    text-decoration: none;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 18px;
    left: 15px;
    z-index: 10;

    span.line {
      padding: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 15px;


      &:first-child {
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        padding: 0;
      }

      &:last-child {
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        padding: 0;
      }
    }
  }

  .mobile-main-menu {
    clear: both;
    display: block;

    li.red > a {
      color: $red !important;
    }

    li.expanded .nolink {
      position: relative;
      display: inline-block;
      padding: 0 20px;

      &:before {
        content: '>';
        top: 50%;
        right: 0;
        transform: translatey(-50%);
        position: absolute;
        display: block;
        font-size: 15px;
      }
    }
    
    & > ul.menu {
      li {
        margin: 0;
        width: 100%;
        position: relative;
        text-align: center;
        padding: 10px;
        
        span {
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 15px;
          text-align: center;
          letter-spacing: 0.2em;
          color: #fff;
        }

        a {
          display: block;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 15px;
          text-align: center;
          letter-spacing: 0.2em;
          color: #fff;
        }

        .mobile-submenu {
          display: none;

          &.open {
            display: block;
            position: fixed;
            z-index: 499;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            box-shadow: none;
            text-align: center;
            background: $black;
            top: 100px;
          }

          span {
            padding-bottom: 0;
          }

          a {
            padding: 0;
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            font-size: 14px;
            letter-spacing: 0.15em;
            color: #fff;
          }

          a.mobile-menu-close {
            padding: 0;
          }

          .submenu-title {
            padding: 25px 0 0;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            letter-spacing: 0.2em;
            color: #fff;
            line-height: 20px;
            border-bottom: 1px solid #fff;
            display: inline-block;

            span {
              padding: 0;
              color: #fff;
              font-size: 14px;
              float: right;
            }
          }

          .close-submenu {
            border: 0;
            text-align: left;
            padding: 15px;
            clear: both;
            color: #fff;
            position: relative;
            
            .back {
              padding: 0;
              text-align: left;
              display: inline-block;
              font-style: normal;
              font-weight: normal;
              line-height: normal;
              font-size: 12px;
              letter-spacing: 0.1em;
              color: #fff;
            }

            .view-all {
              display: inline-block;
              float: right;
              padding: 0;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              letter-spacing: 0.1em;
              color: #fff;
            }
          }
        }
      }
    }
  }
}