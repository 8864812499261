.user-block {
  float: right;
  position: relative;
  display: none;

  @include breakpoint($desk) {
    display: inline-block;
    margin: 0 15px;

    @include breakpoint(1200px) {  
      margin: 0 20px;
    }
  }

  a.user-button {
  	color: $black;
    position: relative;
    font-size: 18px;
    font-weight: bold;

    &:before {
      @extend .font-icon;
      @extend .icon-login;
      color: $grey;
      transform: translate(-5px);
    }
  }

  .user-block-wrapper {
    line-height: 40px;
    top: 5px;
    position: relative;
  }

  &.open-user-block {
    .options-wrapper {
      display: block;
    }
  }

  .options-wrapper {
    display: none;
    position: absolute;
    top: 30px;
    right: -10px;
    z-index: 121;
    padding: 10px 0 0 0;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
      background: #fff;
      padding: 0;
      width: 180px;
      border: 1px solid $background;
      border-bottom: 0;

      &:last-child {
        border-bottom: 1px solid $background;
      }

      a {
        text-align: left;
        display: block;
        width: 100%;
        line-height: 40px;
        font-size: 14px;
        padding: 0 20px 0 40px;
        position: relative;
        color: $black;

        &:hover {
          // background: repeating-linear-gradient( 45deg, #fff, #fff 0, #CCFBF5 15px, #fff 15px );
          background: $background;
          color: #fff;
          text-decoration: none;

          &:before {
            color: #fff;
          }

          .fa-sign-out,
          .fa-pencil {
            color: #fff;
          }
        }
        

        &:before {
          @extend .font-icon;
          position: absolute;
          color: #767676;
          top: 10px;
          left: 11px;
          font-size: 14px;
          line-height: 21px;
          transition: all .3s;
          left: 10px;
        }

        &.wishlist:before {
          @extend .icon-heart;
        }

        &.my-orders:before {
          @extend .icon-shopping-bag;
        }

        .fa-sign-out,
        .fa-pencil {
          position: absolute;
          color: #767676;
          top: 10px;
          font-size: 14px;
          line-height: 21px;
          transition: all .3s;
          left: 13px;
        }
      }
    }
  }
}

.page-user.not-logged-in {
	.l-content {
		margin-bottom: 60px;

		form {
			background: #fff;
			max-width: 405px;
			margin: 40px auto 0;
			padding: 30px;

      input[type="text"],
      input[type="email"],
      input[type="password"],
      select, 
      textarea {
        border-bottom: 1px solid $black;
      }
		}
	}
}

#edit-picture,
.user-profile {
  .user-picture {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.ms-products-user-purchase-options-form {
  margin: 40px 0;
}

.user-profile {
  position: relative;

  .account-settings-links {
    display: inline-block;
    top: 20px;
    width: 140px;
    text-align: center;
    right: 0;
    padding: 0 20px;
    line-height: 40px;
    background: $grey;
    color: #fff;
    margin-bottom: 10px;

    @include breakpoint($desk) {
    	position: absolute;
      margin: 0;
    }

    &:hover {
      background: $black;
    }

    &.memberships-link {
      top: 70px;
    }
  }

  .field {
    display: inline-block;
    margin-right: 40px;
    font-size: 18px;

    .field__label {
      font-size: 13px;
      color: $grey;
      text-transform: uppercase;
    }
  }

  .user-profile-category-history {
    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.user-profile-form {
  max-width: 540px;
  margin: 0 auto;

  fieldset.date-combo .container-inline-date .date-padding {
    padding: 0;
  }

  .field-type-datetime {
    legend {
      color: $grey;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

.form-item-mail {
  position: relative;
}

.forgot-password {
  margin-bottom: 20px;
  font-size: 12px;
  text-align: right;

  a {
    color: $black;
    font-weight: bold;
  }
}

.info-tooltip {
  position: absolute;
  z-index: 100;
  bottom: 9px;
  right: 11px;
  display: table;
  .info-icon {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    background: $grey;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    color: #fff;
  }
  .info-content {
    position: absolute;
    display: none;
    top: 22px;
    right: 0;
    width: 360px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    background: #f6f9fc;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
  }

  &:hover .info-content {
    display: block;
  }
}

.information-text {
  font-size: 12px;
  line-height: 16px;
  margin: 20px auto 0;
  max-width: 405px;
}

.sign-in-link,
.sign-up-link {
  background: $background;
  line-height: 20px;
  max-width: 405px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  color: $black;
  font-size: 12px;

  &.sign-in-link-dark {
    background: $black;
    color: #fff;
  }

  label {
    margin: 0;
  }

  a {
    text-decoration: underline;
    font-size: 14px;
    color: $black;
    line-height: 20px;
    margin-left: 30px;
  }

}

.sign-in-link a {
  color: #fff;
}



