@font-face {
  font-family: "myfont";
  src: url('../fonticon/fonts/myfont.eot');
  src: url('../fonticon/fonts/myfont.eot?#iefix') format('eot'),
    url('../fonticon/fonts/myfont.woff2') format('woff2'),
    url('../fonticon/fonts/myfont.woff') format('woff'),
    url('../fonticon/fonts/myfont.ttf') format('truetype'),
    url('../fonticon/fonts/myfont.svg#myfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-icon:before {
  display: inline-block;
  font-family: "myfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.font-icon-2x { font-size: 2em; }
.font-icon-3x { font-size: 3em; }
.font-icon-4x { font-size: 4em; }
.font-icon-5x { font-size: 5em; }
.font-icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.icon-arrow-left:before { content: "\EA01" }
.icon-arrow-right:before { content: "\EA02" }
.icon-bag:before { content: "\EA03" }
.icon-bell:before { content: "\EA04" }
.icon-check:before { content: "\EA05" }
.icon-close:before { content: "\EA06" }
.icon-concept-icon:before { content: "\EA07" }
.icon-delete:before { content: "\EA08" }
.icon-down-arrow:before { content: "\EA09" }
.icon-eye:before { content: "\EA0A" }
.icon-facebook:before { content: "\EA0B" }
.icon-heart-full:before { content: "\EA0C" }
.icon-heart:before { content: "\EA0D" }
.icon-hover-link:before { content: "\EA0E" }
.icon-insta:before { content: "\EA0F" }
.icon-login:before { content: "\EA10" }
.icon-mail:before { content: "\EA11" }
.icon-minus:before { content: "\EA12" }
.icon-next:before { content: "\EA13" }
.icon-phone:before { content: "\EA14" }
.icon-pin:before { content: "\EA15" }
.icon-plus:before { content: "\EA16" }
.icon-prev:before { content: "\EA17" }
.icon-product-right-arrow:before { content: "\EA18" }
.icon-search:before { content: "\EA19" }
.icon-shopping-bag:before { content: "\EA1A" }
.icon-small-logo:before { content: "\EA1B" }
.icon-submit-arrow-white:before { content: "\EA1C" }
.icon-submit-arrow:before { content: "\EA1D" }
.icon-triangle-down:before { content: "\EA1E" }
.icon-up-arrow:before { content: "\EA1F" }
.icon-wchicon:before { content: "\EA20" }
