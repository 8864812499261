.cart-block {
	float: right;
	position: inherit;
  cursor: pointer;

	@include breakpoint($tab) {
    margin: 0;

    @include breakpoint($desk) {
  		margin: 0 15px;

      @include breakpoint(1200px) {
          margin: 0 20px;
      }
    }
	}

  body.cart-block-active & {
    .cart-button:before {
      color: $background;
    }
  }

	.cart-wrapper {
		position: relative;
		line-height: 16px;
    text-align: center;

		@include breakpoint($desk) {
			top: 5px;
			line-height: 40px;
      text-align: left;
		}

		.cart-button {
			font-size: 16px;
      color: $black;
      font-weight: bold;

			&:before {
				@extend .font-icon;
				@extend .icon-shopping-bag;
        font-size: 23px;
        display: block;
        color: $black;

        @include breakpoint($desk) {
          transform: translate(-5px);
          font-size: 18px;
          display: inline-block;
        }
			}
    }

    .cart-checkout-link {
      display: none;
      line-height: 20px;
      font-size: 13px;
      margin-left: 10px;
      padding: 0 30px 0 10px;
      border: 1px solid $blue;
      position: relative;

      &:before {
        display: block;
        position: absolute;
        content: '';
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid $blue;
        top: 4px;
        right: 10px;
      }
    }
	}

	#cart-wrapper-total {
		display: none;
	}

	#cart-wrapper-items {
		position: absolute;
    bottom: 18px;
    left: auto;
    right: 8px;
    width: 15px;
    text-align: center;
    background: transparent;
    font-size: 10px;
    line-height: 10px;
    padding: 3px;
    border-radius: 50%;
    color: #fff;

    @include breakpoint($desk) {
      bottom: 14px;
      left: -3px;
      right: auto;
    }
	}

	#cart-wrapper {
    display: none;
    z-index: 1499;
    right: 0;
    width: 100%;
    max-width: 375px;
    height: 100%;
    overflow: auto;
    text-align: center;
    background: #fff;
    padding-top: 20px;
    position: fixed;
    top: 0;
    -webkit-animation: fade-out .3s 1 ease-in;
    animation: fade-out .3s 1 ease-in;

    @include breakpoint($tab) {
      height: auto;
      border-top: 1px solid $grey_icon;
      border-bottom: 1px solid $grey_icon;
      border: 1px solid $grey_icon;
      width: 375px;
      position: absolute;
      top: 55px;
    }

    .cart-empty-block {
      margin-bottom: 20px;
      padding: 0 20px;
      text-align: center;
    }

    .close-cart {
      text-decoration: none;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 10px;
      cursor: pointer;
      z-index: 10;


      .line {
        display: block;
        width: 100%;
        height: 1px;
        background: $black;
        position: absolute;
        top: 7px;

        &:first-child {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);

          transform: rotate(45deg);
        }

        &:last-child {
          -ms-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }

    .cart-title {
      font-size: 14px;
      font-weight: bold;
      color: $black;
      margin: 0 0 20px;
      padding: 0 20px;
      text-align: center;
      position: relative;

      &:before {
        @extend .font-icon;
        @extend .icon-hover-link;
        color: $background;
        transform: translatex(-10px);
      }
    }
    .views-table {
      margin-top: 0;
    }

    .views-field.views-field-quantity {
      display: none;
    }

    .prices-wrapper {
      font-weight: 400;
      float: left;
      margin-top: 4px;
      text-align: center;

      & > * {
        line-height: 18px;
        display: inline-block;
      }

      .final-price {
        font-size: 16px;

        &.red-price {
          color: $red;
        }
      }

      .first-price {
        font-size: 15px;
        -webkit-text-decoration-line: strikethrough;
        text-decoration-line: strikethrough;
        text-decoration: line-through;
      }
    }

    .views-field-edit-delete {
      // float: right;
      // margin-top: 5px;
      opacity: 0.7;
      padding-left: 0;


      &:hover {
        opacity: 0.9;
      }

      span {
        position: relative;
        
        &:before {
          @extend .font-icon;
          @extend .icon-delete;
          color: $red;
          position: absolute;
          top: 0;
          left: -3px;
          z-index: -2;
        }
      }

      input {
        // width: 25px;
        // height: 25px;
        // display: block;
        // font-size: 0;
        // background: transparent;
        // border: 0;
        // position: relative;
        // z-index: 1;
        background-image: url(/sites/all/themes/custom/rocket_path_theme/svg/delete.svg);
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
        background-size: 18px;
        border: 0;
        font-size: 0;
      }
    }

    .title-image-wrapper {
      text-align: left;

      .image-wrapper {
        // max-width: 50px;
        float: left;
        display: inline-block;
        position: relative;

        a {
          margin-right: 0;
        }

        img {
          width: 100%;
          height: auto;
          max-width: 90px;
        }

        span.quantity {
          position: absolute;
          font-size: 12px;
          display: block;
          right: -10px;    
          border-radius: 50%;
          color: #fff;
          width: 22px;
          height: 22px;
          text-align: center;
          line-height: 22px;
          top: -10px;
          z-index: 1;

          &:before {
            @extend .font-icon;
            @extend .icon-hover-link;
            z-index: -1;
            color: $background;
            font-size: 16px;
            position: absolute;
            top: 2px;
            left: 3px;
          }
        }
      }

      .title-wrapper {
        width: calc(100% - 100px);
        display: inline-block;
        float: left;
        text-align: left;
        margin-top: 15px;
      }

      a {
        font-size: 9px;
        text-align: left;
        color: $black;
        font-weight: 300;
      }
    }

    body.cart-block-active & {
      display: block;
      -webkit-animation: fade-in .2s 1 ease-in;
      animation: fade-in .2s 1 ease-in;
    }

    .view-content {
      .views-row {
        @include pie-clearfix;
      }

      .views-table td:last-child {
        padding-right: 0;
      }

      .commerce-price-formatted-components {
        margin: 0;

        tr {
          background: none;
        }

        .component-title {
          display: none;
        }
      }
    }

    .cart-contents {
      padding: 0 45px 25px;
    }

    .mini-cart-footer-info {
      color: $grey;
      font-size: 15px;
      line-height: 19px;
      font-family: $second_font;
      margin-top: 15px;
    }


    .line-item-summary {
      margin: 0;
      padding: 10px 0 0;
      clear: both;

      .line-item-quantity {
        display: none;
      }

      .line-item-total {
        .line-item-total-label {
          font-weight: 300;
        }
        .line-item-total-raw {
          font-weight: 400;
        }
      }
    }

    ul.links {
      text-align: center;
      margin-top: 10px;

      li {
        float: none;
        margin: 0 5px;

        &:first-child {
          margin-bottom: 10px;
        }

        @include breakpoint($tab) {
          margin: 0;
        }

        &.line-item-summary-checkout {
          background: #000;
          a {
            color: #fff;
          }
        }
      }

      a {
        background: transparent;
        color: $black;
        width: 100%;
        display: inline-block;
        padding: 0 20px;
        line-height: 43px;
        font-size: 16px;
        border: 1px solid $background;
        outline: none;

        &:hover {
          transition: all .2s;
          // background: repeating-linear-gradient(45deg, #fff, #fff 0, #CCFBF5 15px, #fff 15px);
          background: $background;
          color: #fff;
        }
      }
    }
  }
}

.cart-block-active-mobile {
  overflow: hidden;
}

.cart-block-active {
  .l-header {
    .active-cart-overlay {
      display: block;
      background: rgba(228, 228, 228, 0.6);
      top: 100%;
      left: 0;
      width: 100%;
      height: 1000px;
      position: absolute;
      z-index: 1;

      @include breakpoint($tab) {
        display: none;
      }
    }
  }
}