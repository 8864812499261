// Colors
$black: #000000;
$grey: #C4C4C4;
$green: #67BCA8;
$red: #EB5757;
$blue: #6A8EBE;
$border: #737373;
$grey_icon: #CBCBCB;
$background: #CBCBCB;
$background_light: #F4F4F4;

// Breakpoint variables.
$mob: 360px;
$tab: 760px;
$desk: 1000px;
$wide: 1480px;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Karla:400,700');
$default_font: 'Karla', sans-serif;
$second_font: 'Karla', sans-serif;

$main: $background;
$base: $black;
$neutral: $background_light;

$font-size-h1: 30px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 14px;
$font-size-h6: 12px;