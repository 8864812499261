.wishilist-block {
	float: right;
	margin: 0 15px;
	line-height: 40px;
	display: none;

	@include breakpoint($tab) {
		margin: 0px 15px 0 10px;
		display: block;

		@include breakpoint($desk) {
			margin: 0 15px;
		}
	}

	.wishlist-link {
		display: inline-block;
		margin-top: 2px;
		color: $black;

		.heart-icon {
			font-size: 17px;
			@extend .font-icon;
			@extend .icon-heart;
		}

		&:hover {
			.heart-icon {
				@extend .icon-heart-full;
			}
		}
	}
}


.page-wishlist {
	h1 {
		margin: 30px auto;
	}

	.view-wishlist {
		margin-bottom: 70px;
	}
}