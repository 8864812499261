@import "compass";
@import "breakpoint";
// @import "font-awesome";

// Import variables, abstractions, base styles and components.
@import "abstractions/animation.scss";

// Uncomment the next lines and adjust them to your needs.
@import "variables/variables.scss";
@import "variables/icons.scss";
//@import "abstractions/{YOUR FILE NAME HERE}";
@import "base/default.scss";
@import "base/grid.scss";
@import "base/table.scss";
//@import "components/{YOUR FILE NAME HERE}";
@import "components/general.scss";
@import "components/tabs.scss";
@import "components/navigation.scss";
@import "components/form.scss";
@import "components/messages.scss";
@import "components/product.scss";
@import "components/user.scss";
@import "components/page.scss";
@import "components/commerce.scss";
@import "components/views.scss";
@import "components/wishlist.scss";
@import "components/category.scss";
@import "components/mkt_posts.scss";
@import "components/checkout.scss";

// --------- Page ----------

.l-page {
	overflow: hidden;
}

// body.menu-block-hover {
// 	.highlighted-region {
// 	  position: relative;

// 	  &:after {
// 	    content: '';
// 	    position: absolute;
// 	    top: 0;
// 	    left: 0;
// 	    display: block;
// 	    z-index: 100;
// 	    width: 100%;
// 	    height: 100%;
// 	    background: rgba(255, 255, 255, 0.6);
// 	  }
// 	}
// }

// --------- Header ----------
.header-top {
	// background: #cd1b1b;
	background: #000;
	color: #fff;
	text-align: center;
	width: 100%;
    height: 35px;

	p {
		font-size: 13px;
		line-height: 35px;
		position: absolute;
		top: 0;
		left: 100%;
		text-align: center;
		width: 400%;

		@include breakpoint($tab) {
			width: 300%;

			@include breakpoint($desk) {
				width: 200%;
			}
		}
	}
}

.l-header {
	position: relative;
	z-index: 100;
}

.open-menu-mobile {
	overflow: hidden;

	.header-top {
		display: none;
	}

	.l-header {
		background: $black;

		.header-left,
		.header-right {
			padding-top: 15px;
		}

		.l-header-inner .header-left .burger-wrapper {
			margin-top: 0;
		}

		.cart-block .cart-wrapper {
	    .cart-button {
		    color: #fff;

		    &:before {
    	    color: #fff;
		    }
	    }

	    #cart-wrapper-items {
	    	color: $black;
	    }
		}
	}
}

.l-header-inner {
	@include pie-clearfix;
	padding: 15px 20px 10px;

	@include breakpoint($tab) {
		padding: 20px 35px;
	}


	.header-left,
	.header-right {
		float: left;
		width: 20%;

		@include breakpoint($tab) {
			width: 42%;
		}
	}

	.l-branding {
		float: left;
		width: 60%;
		text-align: center;
		padding-top: 2px;

		@include breakpoint($tab) {
			width: 16%;
			//padding-top: 20px;
		}

		img {
			max-width: 115px;

			@include breakpoint($tab) {
				max-width: 155px;
			}
		}

		.small-logo {
			display: none;
		}

		.open-menu-mobile & {
			.logo {
				display: none;
			}

			.small-logo {
				display: block;
				@extend .font-icon;
				@extend .icon-small-logo;
				font-size: 80px;
				color: #fff;
			}
		}
	}

	.header-left {
		@include breakpoint($desk) {
			padding-top: 15px;
		}

		.burger-wrapper {
			position: relative;
			width: 33px;
			height: 17px;
			-webkit-transform: rotate(0deg);
		  -moz-transform: rotate(0deg);
		  -o-transform: rotate(0deg);
		  transform: rotate(0deg);
		  -webkit-transition: .5s ease-in-out;
		  -moz-transition: .5s ease-in-out;
		  -o-transition: .5s ease-in-out;
		  transition: .5s ease-in-out;

		  @include breakpoint($tab) {
		  	margin-top: 10px;

		  	@include breakpoint($desk) {
			  	margin-top: 20px;
			  }
		  }

			.line {
				width: 100%;
				background: $black;
				border-radius: 2px;
				height: 2px;
				display: block;
				margin-bottom: 3px;
				display: block;
  			position: absolute;
  			opacity: 1;
				-webkit-transform: rotate(0deg);
			  -moz-transform: rotate(0deg);
			  -o-transform: rotate(0deg);
			  transform: rotate(0deg);
			  -webkit-transition: .25s ease-in-out;
			  -moz-transition: .25s ease-in-out;
			  -o-transition: .25s ease-in-out;
			  transition: .25s ease-in-out;

			  &:nth-child(1) {
					top: 0;
			  }
			  &:nth-child(2),
			  &:nth-child(3) {
			  	top: 9px;
			  }
			  &:nth-child(4) {
			  	top: 18px;
			  }

			  .open-menu-mobile & {
			  	&:nth-child(1) {
						top: 7px;
					  width: 0%;
					  left: 50%;
				  }
				  &:nth-child(2) {
				  	-webkit-transform: rotate(45deg);
					  -moz-transform: rotate(45deg);
					  -o-transform: rotate(45deg);
					  transform: rotate(45deg);
					  background: #fff;
				  }
				  &:nth-child(3) {
				  	-webkit-transform: rotate(-45deg);
					  -moz-transform: rotate(-45deg);
					  -o-transform: rotate(-45deg);
					  transform: rotate(-45deg);
					  background: #fff;
				  }
				  &:nth-child(4) {
				  	top: 7px;
					  width: 0%;
					  left: 50%;
				  }
			  }
			}
 
			span.content {
				&:before {
					content: 'menu';
					position: absolute;
	        top: 22px;
	        font-size: 16px;
	        font-weight: 700;
	        color: $black;
	        left: -5px;
				}
			}

			.open-menu-mobile & {
				span.content:before {
					content: 'close';
					position: absolute;
          top: 27px;
          left: -3px;
          color: #fff;
				}
			}
		}

		.site-icon img {
			max-width: 68px;
		}
	}

	.header-right {
		position: relative;

		@include breakpoint($tab) {
			padding-top: 10px;
		}
	}
}

.search-form {
  @include pie-clearfix;

  .form-wrapper {
    @include breakpoint($tab) {
		text-align: center;
	    margin: 30px 0;	
    }
  }

  .form-type-textfield {
    max-width: 640px;
    display: inline-block;

    label {
    	text-align: center;

    	@include breakpoint($tab) {
    		text-align: left;
    	}
    }

    input[type="text"] {
    	border-bottom: 1px solid $border;
    }
  }

  input[type="submit"] {
  	line-height: 20px;
    margin: 20px;
  }
}

.block--search-form {
  display: none;
  float: right;
  position: inherit;

  @include breakpoint($desk) {
	  margin: 0 15px;
	  display: inline-block;

	   @include breakpoint(1200px) {
				margin: 0 20px;
	   }
	}
  
  h2.element-invisible {
  	height: 0;
  }

  label.element-invisible {
  	position: relative !important;
    text-transform: lowercase;
    height: 100%;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;
  }

  .search-block-form {
  	margin: 16px 0 0;
    display: block;

    input[type="text"] {
      background: transparent;
      border: 0;
      line-height: 16px;
      box-shadow: none;
      width: 20px;
      padding: 0;
      display: none;

      @include breakpoint($tab) {
      	width: 10px;
      	padding: 0 10px;
	      line-height: 40px;

      	@include breakpoint($desk) {
		      padding: 0 20px;
		      width: 30px;
		    }
	    }
    }

    // .form-item-search-block-form {
    //   position: relative;
    // }

    input[type="submit"] {
      display: none;
    }
  }

  &.open-search {
    .search-block-form {
      -webkit-animation: open-search .3s 1 ease-in;
      animation: open-search .3s 1 ease-in;

      input[type="text"] {
        background: $background;
        border: 1px solid $background;
        width: 240px;
        cursor: initial;
        -webkit-animation: open-search-text .3s 1 ease-in;
        animation: open-search-text .3s 1 ease-in;
        display: block;
        position: absolute;
        right: 0;
        margin-top: 20px;
      }

      .form-item-search-block-form {
        &:before {
          right: 5px;
        }
      }

      input[type="submit"] {
        width: 40px;
        height: 40px;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        padding: 0;
      }
    }
  }
}

.mobile-search-form {
	padding: 20px;
	background: $background;
	display: none;

	&.show {
		display: block;
	}

	.rocket-path-elastic-instant-search {
    cursor: pointer;
    @extend .container;

    .container-inline {
    	text-align: center;
    }

    input[type="text"] {
      border: 0;
      padding: 0 20px;
      line-height: 40px;
      width: 100%;
      box-shadow: none;
    }

    .form-item-search-block-form {
      position: relative;
      
      &:before {
        @extend .font-icon;
        @extend .icon-search;
        color: $black;
        font-size: 17px;
        top: 50%;
        right: 0;
        position: absolute;
        line-height: 0;
        transform: translate(-50%,0);
      }
    }

    input[type="submit"] {
      display: none;
    }
  }
}

.highlighted-region {
	@include pie-clearfix;

	.l-region--highlighted {
		width: 100%;
		margin: 0 auto;

		@include breakpoint($tab) {
			max-width: $tab;
		}

		@include breakpoint($desk) {
			max-width: 100%;
		}

		@include breakpoint($wide) {
			max-width: $wide;
		}

		.left-high {
			width: 100%;
			float: left;
			margin-bottom: 10px;

			@include breakpoint($tab) {
				width: 60%;
				margin-bottom: 25px;
			}
		}

		.new-arrivals.left-high {
			@include breakpoint($tab) {
				width: 100%;

				@include breakpoint($desk) {
					width: 60%;
				}
			}
		}

		.right-high {
			width: 100%;
			float: right;
			padding: 0 40px 0;
			background: linear-gradient(to bottom, #fff 0%,#fff 50%,$background 50%,$background 50%,$background 100%);

			@include breakpoint($tab) {
				width: 40%;
				padding-left: 70px;
				background: transparent;
				padding-top: 15px;

				@include breakpoint($desk) {
					padding-top: 80px;
    			padding-right: 0;
				}
			}
		}

		.term-info {
			margin: 0 auto;
			clear: both;
			@extend .container;
			position: relative;
			padding: 0;

			@include breakpoint($tab) {
				margin: 35px auto 50px;
			}

			// .l-breadcrumb {
			// 	// position: absolute;
			// 	// top: 0;
			// 	// left: 20px;
			// 	@extend .container;
			// 	line-height: 42px;

			// 	a {
			// 		color: $black;
			// 		font-size: 13px;
			// 		font-weight: 300;
			// 	}

			// 	li {
			// 		display: inline-block;
			// 		margin-right: 5px;
			// 		position: relative;
			// 		padding-right: 12px;
			// 		font-size: 13px;
			// 		font-weight: 300;

			// 		&:after {
			// 			content: '/';
			// 			position: absolute;
			// 			top: 0;
			// 			right: 0;
			// 		}

			// 		&:last-child:after {
			// 			display: none;
			// 		}

			// 		&:last-child {
			// 			font-weight: 700;
			// 		}
			// 	}
			// }

			.block__content.empty-term-content {
				.term-designer-title {
					margin: 0;

					&:before {
						display: none;
					}

				}

				.l-breadcrumb {
					position: absolute;
					top: 0;
					left: 20px;
				}
		  }
		}

		.high-info {
			text-align: center;
			font-size: 15px;
			line-height: 18px;
			padding: 14px 20px;

			.green-text,
			.red-text {
				margin: 0 7px;

				@include breakpoint($tab) {
					display: inline-block;
				}	
			}
		}
	}
}

// ------------ Blue Background ------------
.l-blue-background {
	background: $blue;
	color: #fff;
	padding: 15px 20px;

	.block {
		text-align: center;

		.block__title {
			font-family: $second_font;
			font-size: 27px;
			line-height: 41px;

			@include breakpoint($tab) {
				font-size: 43px;
				line-height: 43px;
			}
		}

		.block__content {
			p {
				margin: 10px 0;
				font-weight: 300;
				line-height: 21px;
				font-size: 15px;

				@include breakpoint($tab) {
					font-size: 13px;
					line-height: 19px;
				}
			}

			a {
				display: inline-block;
				border: 1px solid #fff;
				font-size: 16px;
				line-height: 16px;
				color: #fff;
				padding: 10px 40px;
				margin-top: 25px;

				@include breakpoint($tab) {
					margin-top: 15px;
					font-size: 15px;
					line-height: 16px;
				}

				&:hover {
					font-style: normal;
					color: $blue;
					background: $background;
					border: 1px solid $background;
				}
			}
		}
	}
}

// ------------ Light Grey Background --------
.l-light-grey-background {
	// background: $background_light;
	padding: 25px 0;

	@include breakpoint($tab) {
		padding: 40px 0 25px;
	}
}

// ------------ Newsletter --------
.l-newsletter {
	background: transparent;
	color: $black;
	// border-top: 1px solid $border;

	@include breakpoint($tab) {
		padding: 45px 0;
	}

	.l-newsletter-wrapper {
		@extend .container;
		@include pie-clearfix;
		max-width: 950px;
    margin: 0 auto;

		.newsletter-form {
			margin-top: 35px;
			text-align: center;

			@include breakpoint($tab) {
				margin-top: 10px;

				@include breakpoint($desk) {
					margin-top: 35px;
				}
			}

			.node__title,
			.node--webform--full .field--name-title-field {
				display: none;
			}

			.field--name-body {
    		font-size: 20px;
    		line-height: 23px;
    		text-align: center;
		    max-width: 400px;
		    margin: 0 auto;
		    padding: 0 20px 20px;

    		@include breakpoint($desk) {
    			float: left;
		    	display: inline-block;
			    width: 30%;
			    text-align: left;
			    padding: 5px 0 0;
			  }
			}

			.webform-client-form-1 {
				@include breakpoint($desk) {
					width: 70%;
					display: inline-block;
					text-align: right;
				}
			}

			.form-item {
				margin-top: 20px;

				@include breakpoint($tab) {
					display: inline-block;
					margin: 0;
				}

				input[type="email"] {
					max-width: 445px;
			    border: 1px solid #000;
			    line-height: 55px;
			  }
			}

			.form-actions {
		    margin-bottom: 0;
 				display: inline-block;
			}

			input[type="submit"] {
		    background: transparent;
		    color: $black;
		    padding: 24px 58px 28px 28px;
		    border: 1px solid $black;
		    font-size: 16px;
		    cursor: pointer;
		    border-radius: 0;
		    background-image: url(../svg/submit-arrow.svg);
		    background-repeat: no-repeat;
		    background-position: 75% 50%;
		    font-weight: bold;
		    transition: all .2s ease-in-out;

		    @include breakpoint($tab) {
		    	border-left: 0;
		    }

		    &:hover {
		    	@include breakpoint($tab) {
	    	    background-image: url(/sites/all/themes/custom/rocket_path_theme/svg/submit-arrow.svg);
				    background-repeat: no-repeat;
				    background-position: 85% 50%;
				  }
		    }
			}
		}
	}
}

// ------------ Pre Footer --------
.l-prefooter {
	// background: $background_light;
	padding: 35px 0;
}

// ------------ Footer --------
.l-footer-wrapper {
	padding: 0px 0 44px;

	.l-region--footer {
		@extend .container;
		@include pie-clearfix;
		padding: 30px 0;

		.footer-menu {
			@include pie-clearfix;
			margin: 0;

			ul.menu {
				padding: 0;
				margin: 0;
				list-style-type: none;
				list-style-image: none;
				list-style: none;
				text-align: center;

				li {
					list-style-type: none;
					list-style-image: none;
					list-style: none;
					text-align: center;
					display: inline-block;
					padding: 0 10px;

					// @include breakpoint($tab) {
					// 	text-align: left;
					// }

					a {
						font-weight: 300;
						line-height: 27px;
						font-size: 16px;
						color: $black;

						&:hover {
							text-shadow: 1px 0 $black;
						}
					}

					span {
						display: none;
					}
				}

				& > .expanded {
					@include breakpoint($tab) {
						width: 33.3333%;
						display: inline-block;
						float: left;
					}
				}
			}
		}
	}
}

// ------------ Footer Bottom --------

.footer-bottom {
	// border-top: 1px solid $border;
	// border-bottom: 1px solid $border;
	text-align: center;
	padding: 17px 0;
	font-weight: bold;
	font-size: 14px;
}

// ----------- Social -----------

.social-links {
	text-align: center;
	padding: 30px 0 0;

	@include breakpoint($tab) {
		padding: 30px 0;
	}

	.facebook {
		padding-bottom: 5px;
	}
}

// --------------- Product Teasers ----------------
.product-teasers {
	margin-bottom: 35px;
	@extend .container;

	.block__title {
		font-family: $second_font;
		font-weight: bold;
		font-size: 23px;
		line-height: 29px;
		margin: 15px 0 40px;
		text-align: center;

		@include breakpoint($tab) {
			line-height: 34px;
			font-size: 34px;
			margin: 55px 0 60px;
		}
	}

	.views-row {
		margin-bottom: 30px;

		@include breakpoint($tab) {
			margin-bottom: 0;
		}
	}
}

// --------------- Contact Page ----------------

.page-node-1501 {
	.l-content {
    padding: 0;
    max-width: 100%;
	}

	.node--page--full {
		margin: 0;
		background: #fff;
		padding: 0;

		@include breakpoint($tab) {
			padding: 60px 20px;
		}

		h1 {
			margin: 0
		}
	}

	.content-wrapper {
		background: $background;
	}

	.contact-details {
		text-align: center;
    vertical-align: top;

    .block__content {
    	padding: 20px 0 40px;

    	@include breakpoint(845px) {
    		padding: 20px;
    	}
    }

    p {
	    color: #909090;
      line-height: 20px;
    	font-size: 13px;

    	@include breakpoint($tab) {
	    	line-height: 27px;
		    font-size: 15px;
		  }

	    &:last-child {
	    	margin-top: 20px;

	    	@include breakpoint($tab) {
		    	margin-top: 30px;
		    }
	    }
    }

    .blue {
			p {
				color: $blue;
				margin: 0 auto 20px;
				line-height: 20px;
    		font-size: 20px;

				@include breakpoint($tab) {
					margin: 0 auto 30px;
					line-height: 27px;
					font-size: 24px;
				}
			}
    }
	}
}

// --------------- Contact Page ----------------

.node--webform--full {

  .field--name-title-field {
    font-size: 28px;
    letter-spacing: .09em;
    line-height: 30px;
    text-align: center;
    line-height: 42px;
    font-family: $second_font;
    margin: 0 auto 10px;
  }

  .field--name-body {
    max-width: 880px;
    padding: 0 20px;
    margin: 0 auto 60px; 
    text-align: center;
  }

  .webform-client-form-20 {
	  @include pie-clearfix;
	  max-width: 840px;
	  margin: 0 auto 60px;

	  .form-item {
	    width: 100%;

	    @include breakpoint($tab) {
	      width: 50%;
	      float: left;
	      display: inline-block;
	      padding: 0 10px;
	    }

	    &.webform-component-textarea {
	      @include breakpoint($tab) {
	        width: 100%;

	        .form-textarea-wrapper {
	        	max-width: 100%;
	        }
	      }
	    }

	    input[type="text"], 
	    input[type="email"] {
		    border: 0;
		    background: none;
		    color: #000;
		    font-weight: 300;
		    padding: 0 10px;
		    font-size: 15px;
		    line-height: 20px;
		    position: relative;
	   		border-bottom: 1px solid #000;
	    }

	    textarea {
	    	border: 1px solid #000;
	    }
	  }
      
      .form-actions {
      	clear: both;
  	    padding-top: 25px;
      }

	  .form-submit {
	  	clear: both;
	    display: block;
	    margin: 10px auto 0;
	    font-weight: 700;
	  }
	}
}
.node--article--full {
	margin: 0;
	background: #fff;
	padding: 0;

	@include breakpoint($tab) {
		padding: 60px 20px;
	}

	h1 {
		margin: 0;
		line-height: 25px;

		@include breakpoint($desk) {
			 line-height: 42px;
		}
	}

	.front & {
		display: none;
	}

	.subtitle {
    font-size: 17px;
    text-align: center;
    font-weight: 400;
	}

	.body-wrapper {
		padding: 30px 0;
		@include pie-clearfix;

		@include breakpoint($desk) {
			padding: 100px 0;
		}

		p {
			font-weight: 300;
			line-height: 25px;
			font-size: 14px;
				
			@include breakpoint($desk) {
				line-height: 30px;
				font-size: 18px;
			}
		}
	}

	.left-col {
		display: inline-block;
		width: 100%;
  	text-align: center;

		@include breakpoint($desk) {
			width: 50%;
			text-align: left;
		}

		img {
	    margin-top: 30px;
	    max-width: 250px;

	    @include breakpoint($desk) {
		    max-width: 350px;
	    	margin-top: 50px;
	    }
		}
	}

	.right-col {
		float: right;
		width: 100%;
		padding-top: 30px;

		@include breakpoint($desk) {
			width: 50%;
			padding-top: 0;
		}
	}
}

.front .node--page--full {
  display: none;
}


// --------------- Breadcrumb ----------------

.l-breadcrumb {
	@extend .container;
	line-height: 42px;
  padding: 0 20px;

	a {
		color: $grey;
		font-weight: 300;
		font-size: 11px;

		@include breakpoint($tab) {
			font-size: 13px;
		}
	}

	li {
		display: inline-block;
		margin-right: 5px;
		position: relative;
		padding-right: 12px;
		font-size: 11px;
		font-weight: 300;

		@include breakpoint($tab) {
			font-size: 13px;
		}

		&:after {
			content: '>';
			position: absolute;
			top: 0;
			right: 0;
			color: $grey;
		}

		&:last-child:after {
			display: none;
		}

		&:last-child {
			font-weight: 700;
			color: $black;
		}
	}
}

// --------------- Dropdown Menu Overlay ----------------

.hover-active {
	.highlighted-region {
		position: relative;

		&:after {
	    content: '';
	    position: absolute;
	    top: 0;
	    left: 0;
	    display: block;
	    z-index: 100;
	    width: 100%;
	    height: 100%;
	    background: rgba(255, 255, 255, 0.6);
	  }
	}

	.l-main {
		position: relative;

		&:after {
	    content: '';
	    position: absolute;
	    top: 0;
	    left: 0;
	    display: block;
	    z-index: 100;
	    width: 100%;
	    height: 100%;
	    background: rgba(255, 255, 255, 0.6);
	  }
	}
}

// --------------- Philosophy Page ----------------


.page-node-22 {
	.l-main {
		// border-top: 10px solid $background;

		.l-content.container {
			padding: 0;
			max-width: 100%;
		}

		.node--page--full .field--name-body {
			text-align: center;
			margin: 0 auto 55px;
			padding: 0 20px;

			@include breakpoint($tab) {
				padding: 0;
			}
		}
	}
}

.main-navigation {
	display: none;
}


// Blog

.node--blog--full {
	.l-breadcrumb {
		padding: 0;
	}

	.top-section {
		max-width: 950px;
	    margin: 0 auto;

	    .left-image-wrapper {
		    display: inline-block;
		    width: 50%;
		    float: left;
		    padding-right: 5px;

		    @include breakpoint($tab) {
			    padding-right: 15px;
			  }
	    }

	    .right-image-wrapper {
		    display: inline-block;
		    width: 50%;
		    padding-left: 5px;

		    @include breakpoint($tab) {
			    padding-left: 15px;
			  }
	    }

	    .image-wrapper {
		    display: inline-block;
		    width: 100%;
	    }

	    .date-wrapper {
	    	font-size: 14px;
	    }

	    h1 {
	    	text-align: left;
	    	font-size: 20px;
	    	font-weight: bold;
	    	margin-bottom: 20px;
	    	display: block;

	    	@include breakpoint($tab) {
		    	margin-bottom: 27px;
		    	font-size: 25px;
		    }
	    }

	    .body-wrapper {
	    	text-align: left;
	    	font-weight: bold;
	    	margin: 20px 0;
	    	font-size: 15px;

	    	@include breakpoint($tab) {
		    	margin: 45px 0;
		    	font-size: 20px;

		    	@include breakpoint($desk) {
			    	text-align: center;
			    }
		    }
	    }
	}

	.middle-section {
		position: relative;
		background: #000;
		max-width: 950px;
	  margin: 0 auto;
	  width: 100%;
	  padding: 30px 0;

	  @include breakpoint($desk) {
			display: inline-flex;
		  padding: 65px 0;
		}


		&:before {
	    content: "";
	    display: block;
	    background: #000;
	    right: 100%;
	    position: absolute;
	    width: 1000%;
	    height: 100%;
	    top: 0;
	  }

	  &:after {
	    content: "";
	    display: block;
	    background: #000;
	    left: 100%;
	    position: absolute;
	    width: 1000%;
	    height: 100%;
	    top: 0;
	  }

	  .middle-section-body-wrapper {
	  	width: 100%;
	  	display: inline-block;
	  	float: left;
	  	color: #fff;
	  	font-weight: bold;
	  	position: relative;
	  	font-size: 15px;
	  	margin-bottom: 10px;
	  	text-align: left;

	  	@include breakpoint($tab) {
		  	font-size: 20px;

		  	@include breakpoint($desk) {
		  		margin-bottom: 0;
			  	padding-right: 30px;
			  	width: 50%;
			  	text-align: center;
			  }
		  }

	  	p {
	  		@include breakpoint($desk) {
		  		position: absolute;
		  		top: 50%;
		  		transform: translatey(-50%)
		  	}
	  	}
	  }

	  .image-wrapper {
	  	display: inline-block;
	  	width: 100%;

	  	@include breakpoint($desk) {
		  	padding-left: 30px;
		  	width: 50%;
		  }
	  }
	}

	.bottom-section {
		.carousel-image-wrapper {
			margin: 20px 0;
			@include breakpoint($tab) {
				margin: 45px 0;
			}

			.field__label {
				display: none;
			}

			.image-wrapper {
				position: relative;
		    text-align: center;
			}

			.owl-carousel {	
	    	position: relative;
			}

			.owl-wrapper-outer {
				overflow: hidden;
			}

			.owl-item {
				display: inline-block;
				float: left;
			}

		 .owl-controls { 
			 	@include pie-clearfix;
			 	position: absolute;
		    width: 100%;
		    top: 50%;
		    transform: translateY(-50%);

			  .owl-buttons {
				  .owl-prev {
				    position: absolute;
				    left: 0;
				  }

				  .owl-next {
				    position: absolute;
				    right: 0;
				  }

				  div {
				    color: transparent;
				    zoom: 1;
				    margin: 0;
				    padding: 0;
				    font-size: 12px;
				    -webkit-border-radius: 0;
				    -moz-border-radius: 0;
				    border-radius: 0;
				    background: transparent;
				    opacity: 1;

				  }

				  .owl-next,
				  .owl-prev {
				    color: $black;
				    display: inline-block;
				    line-height: 32px;
				    font-size: 12px;
				    padding-left: 35px;
				    transform: translateX(27px);
				    font-size: 0;
		        cursor: pointer;

				    &:before {
				      width: 38px;
				      height: 24px;
				      @extend .font-icon;
				      font-size: 24px;
				      line-height: 32px;
				      position: absolute;
				      z-index: 1;
				      pointer-events: none;
				      top: 0;
				      left: 0;
				    }
				  }

				  .owl-prev {
				    transform: translate(-28px,4px) rotate(180deg);
				    &:before {
				        content: url('../svg/product-right-arrow.svg');
				      }
				  }

				  .owl-next {
				    &:before {
			        content: url('../svg/product-right-arrow.svg');
			      }
				  }
				}
			}
		}

		.bottom-section-body-wrapper {
			font-weight: bold;
			text-align: left;
			font-size: 15px;
			margin: 25px 0;

			@include breakpoint($tab) {
				margin: 50px 0;
				font-size: 20px;

				@include breakpoint($desk) {
					text-align: center;
				}
			}
		}

		.navigation-nodes-wrapper {
			@include pie-clearfix;
			max-width: 1000px;
			width: 100%;
			margin: 0 auto;

			.prev,
			.next {
		    display: inline-flex;
		    position: relative;
		    width: 100%;

	      @include breakpoint($tab) {
			    width: 50%;
			  }

		    .prev-button {
			    position: absolute;
			    top: 50%;
			    transform: translatey(-50%);
			    background-image: url('../svg/prev.svg');
			    background-repeat: no-repeat;
			    background-position: right 0;
			    padding-top: 20px;
			    left: -7px;
	        font-size: 12px;

			    @include breakpoint($desk) {
				    left: -30px;
		        font-size: 15px;
				  }	
		    }

		    .next-button {
		    	position: absolute;
			    top: 50%;
			    transform: translatey(-50%);
			    background-image: url('../svg/next.svg');
			    background-repeat: no-repeat;
			    padding-top: 20px;
			    right: 17px;
	        font-size: 12px;

			    @include breakpoint($desk) {
				    right: 0;
		        font-size: 15px;
				  }
			  }

		    .left-side {
		    	display: inline-block;
		    	width: 50%;
		    	float: left;

		    	.next-image img,
		    	.prev-image img {
						display: block;
		    	}
		    }

		    .right-side {
		    	display: inline-block;
		    	width: 50%;
		    	background: #000;
		    	position: relative;

		    	a {
		    		color: #fff;
		    	}

		    	.right {
		    		position: absolute;
		    		bottom: 10px;
		    		left: 10px;

		    		@include breakpoint($tab) {
			    		bottom: 20px;
			    		left: 20px;
			    	}

		    		.prev_date-wrapper a,
		    		.next_date-wrapper a {
							font-weight: 100;
							font-size: 12px;

							@include breakpoint($tab) {
								font-size: 14px;
							}
		    		}

		    		.prev_title-wrapper a,
		    		.next_title-wrapper a {
		    			font-size: 17px;
		    			font-weight: bold;

		    			@include breakpoint($tab) {
			    			font-size: 25px;
			    		}
		    		}
		    	}
				}
			}

			.prev {
				padding: 0 20px 0 50px;

				@include breakpoint($desk) {
		    	padding: 0 40px;
		    }
			}

			.next {
				padding: 0 50px 0 20px;

				@include breakpoint($desk) {
		    	padding: 0 40px;
		    }
			}
		}
	}
}


//Blog Teaser
.section-blog {
	h1 {
		display:none
	}
}

.blog-banner {
	background: #000;
	padding: 30px 0;
	position: relative;
  
  h1 {
  	display: block;
		color: #fff;
  }

  .header-body {
  	color: #fff;
  	max-width: 800px;
  	margin: 0 auto;
  	text-align: center;
  	margin-top: 30px;
  }
}

.view-blog {
	.views-row-odd {
		background: #F4F4F4;
		position: relative;
		padding: 30px 0;

		@include breakpoint($tab) {
			padding: 45px 0;
		}

		&:before {
	    content: "";
	    display: block;
	    background: #F4F4F4;
	    right: 100%;
	    position: absolute;
	    width: 1000%;
	    height: 100%;
	    top: 0;
	  }

	  &:after {
	    content: "";
	    display: block;
	    background: #F4F4F4;
	    left: 100%;
	    position: absolute;
	    width: 1000%;
	    height: 100%;
	    top: 0;
	  }

		.left-side {
			@include breakpoint($tab) {
				float: left;
				padding-right: 15px;
			}
		}

		.right-side {
			text-align: left;

			@include breakpoint($tab) {
				padding-left: 15px;
			}

			.right-image-wrapper {
				@include breakpoint($tab) {
					padding-right: 200px;
				}
			}
		}
	}

	.views-row-even {
		background: #fff;
		padding: 30px 0;

		@include breakpoint($tab) {
			padding: 45px 0;
		}

		.blog-teaser {
			@include breakpoint($tab) {
		    display: -ms-flexbox;
		    display: flex;
		    -ms-flex-direction: row-reverse;
		    flex-direction: row-reverse;
		  }
		}

		.left-side {
			@include breakpoint($tab) {
				float: right;
				padding-left: 15px;
			}
		}

		.right-side {
			text-align: left;

			@include breakpoint($tab) {
				text-align: right;
				padding-right: 15px;
			}

			.right-image-wrapper {
				@include breakpoint($tab) {
					padding-left: 200px;
				}
			}
		}
	}
}

.node--blog--teaser {
	text-align: center;
}

.blog-teaser {
	max-width: 950px;
	margin: 0 auto;
	width: 100%;

	@include breakpoint($tab) {
		display: inline-flex;
	}

	a {
		font-weight: 100;
	}

	.left-side,
	.right-side {
		width: 100%;

		@include breakpoint($tab) {
			width: 50%;
			display: inline-block;
			float: left;
		}
	}

	.date-wrapper {
		font-weight: 100;
		padding-top: 5px;
		font-size: 12px;

		@include breakpoint($tab) {
			font-size: 14px;
		}
	}

	.teaser-title {
		font-weight: bold;
		font-size: 20px;
		margin: 8px 0 5px;

		@include breakpoint($tab) {
			font-size: 25px;
			margin: 12px 0 6px;
		}

		a {
			font-weight: 700;
		}
	}

	.body-wrapper {
		font-weight: 100;
		font-size: 14px;
		line-height: 16px;

		@include breakpoint($tab) {
			font-size: 16px;
			line-height: 19px;
		}
	}

	.cta {
		margin-top: 10px;

		@include breakpoint($tab) {
			margin-top: 14px;
		}

		a {
			font-weight: 700;
			background-image: url('../svg/next.svg');
			background-repeat: no-repeat;
			background-position: right center;
			font-size: 16px;
			padding-right: 25px;

			@include breakpoint($tab) {
				padding-right: 30px;
				font-size: 20px;
			}
		}
	}
}


// Order page

.commerce-order-commerce-order {

	.view-commerce-line-item-table {
		table td,
		table th {
	    padding: 15px;
    	text-align: center;
      
      @include breakpoint($tab) { 
	    	padding: 15px 0 15px 20px;
	    	text-align: left;
	    }
		}
	}
  .views-field-line-item-title {
    padding-left: 0;

    .image-wrapper {
	    width: 64px;
	    display: inline-block;
	  }

	  .title-wrapper {
    	display: inline-block;
    	width: 100%;

    	@include breakpoint($tab) {
	  		top: -35px;
	    	right: -15px;
	    	width: auto;
	    }
	  }

    & > div {
      position: relative;
    }

    .quantity {
      position: absolute;
      left: 55px;
      color: #fff;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      top: -10px;
      z-index: 1;

      &:before {
        @extend .font-icon;
        @extend .icon-hover-link;
        z-index: -1;
        color: $background;
        position: absolute;
        font-size: 20px;
        left: 1px;
      }
    }
  }

  .views-field-commerce-unit-price {
  	display: none;

  	@include breakpoint($tab) { 
			display: table-cell;
  	}
  }

  .field--name-commerce-order-total {
		@include pie-clearfix;
	}

	.commerce-price-formatted-components {
		width: 50%;
		float: right;

		tr td {
			padding: 15px 20px;
		}

		.component-type-commerce-price-formatted-amount td {
			font-weight: bold;
			font-size: 16px;
		}
	}

	.field--name-commerce-customer-billing {
		display: inline-block;
		float: left;
		width: 100%;
		margin: 20px 0 10px;

		@include breakpoint($tab) {
			margin: 50px 0;
			width: 50%;
		}

		.field__label {
			font-family: $second_font;
		    font-size: 22px;
		    line-height: 30px;
		    padding-bottom: 10px;
		    font-weight: 100;
		}
	}

	.field--name-commerce-customer-shipping {
		display: inline-block;
		width: 100%;
		margin: 20px 0 10px;

		@include breakpoint($tab) {
			margin: 50px 0;
			width: 50%;
		}

		.field__label {
		    font-size: 22px;
		    line-height: 30px;
		    padding-bottom: 10px;
		    font-weight: 100;
		}
	}
}

































