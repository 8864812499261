ul.tabs {
	border: 0;
	display: table;
	margin: 25px auto 0;

	li {
		display: inline-block;
		float: none;
		margin: 0 5px;

		a {
			border: 1px solid $black;
			color: $black;
			background: none;
			position: relative;
			transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
			padding: 10px;
			font-size: 13px;
            
            @include breakpoint($tab) {
				font-size: 15px;
				padding: 10px 20px;
			}

			&:before {
				display: block;
				position: absolute;
				left: 0px;
				top: 0px;
				height: 0px;
				width: 100%;
				z-index: -1;
				content: '';
				background: $background;
				transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
			}

			&:hover {
				font-style: normal;
				color: $black;
				background: transparent;
				border: 1px solid $background;
				background: repeating-linear-gradient(45deg, #fff, #fff 0, #CCFBF5 15px, #fff 15px);

				&:before {
					bottom: 0%;
					top: auto;
					height: 100%;
				}
			}
		}

		&.active a {
			background: $black;
			color: #fff;
		}
	}
}