.product-teaser {
	text-align: center;
	padding: 0 10px;
	position: relative;

	.product-teaser-title-wrapper {
    h2 a,
    h2 {
      font-weight: 300;
    }

		a {
      line-height: 18px;
      color: $black;
      font-size: 12px;
      font-weight: bold;
      margin: 5px 0;
      display: block;

      @include breakpoint($tab) {
        font-size: 20px;
        margin: 25px 0 5px;
      }
		}
	}

  .out-of-stock-overlay {
    position: relative;

     a {
      display:block;
      text-align: center;
      position: relative;

      &:after {
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        display: block;
        background: rgba(255, 255, 255, 0.3);
        top: 0;
      }
    }

    .out-of-stock-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #000;
      z-index: 1;
      font-weight: bold;
      font-size: 20px;

      a:after {
        display: none;
      }
    }
  }

  .offer-product-tag {
    position: absolute;
    top: 8px;
    left: 10px;
    padding-left: 17px;
    line-height: 15px;
    font-size: 15px;
    color: #000;
    border-bottom: 4px solid #eb5757;
    font-weight: 700;
    text-shadow: 1px 1px #eb5757;
  }

	.product-teaser-body-wrapper {
		margin-bottom: 20px;
    font-size: 12px;
    color: $grey;
    line-height: 18px;

    @include breakpoint($tab) {
      font-size: 15px;
    }
	}

	.prices-wrapper {
		line-height: 21px;
		font-size: 13px;

    @include breakpoint($tab) {
      font-size: 16px;
    }

		.final-price,
    .initial-price {
      margin: 0 4px;
      display: block;

      @include breakpoint($desk) {
        display: inline-block;
      }  
    }

    .initial-price {
      color: #828282;
      -webkit-text-decoration-line: line-through;
      text-decoration-line: line-through;
    }
	}

	.product-teaser-flag-wrapper {
		position: absolute;
		top: 12px;
    right: 5px;

		.flag {
			font-size: 1px;
			color: transparent;

			&:before {
				color: $black;
				font-size: 17px;
				@extend .font-icon;
				@extend .icon-heart;
			}

			&.unflag-action:before {
				@extend .icon-heart-full;
        color: $black;
			}
		}
	}
}

.recommended-block {
  margin-bottom: 50px;

  .view-content {
    text-align: center;
  }
}

.l-breadcrumb.product-breadcrumb {
  padding: 0 10px;
}

.product {
  background: $background_light;
  position: relative;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: $background_light;
    top:0;
    position: absolute;
  }

  &:before {
    left: -100%;
  }

  &:after {
    left: 100%;
  }
}

.node--product--full {
  @include pie-clearfix;
  
  @include breakpoint($tab) {
    margin: 15px auto 0;
  }

  .field__label {
    display: none;
  }

  .left-section {
    width: 100%;
    display: inline-block;
    float: left;
    padding: 0 20px;

    @include breakpoint($desk) {
      width: 30%;
    }
  }

  .middle-section {
    width: 100%; 
    display: inline-block;
    padding: 0 20px;

    @include breakpoint($desk) { 
      width: 40%;
    }
  }
  
  .right-section {
    width: 100%;
    display: inline-block;
    float: right;
    padding: 0 20px;
    margin-bottom: 40px;

    @include breakpoint($desk) {
      width: 30%;
       margin-bottom: 0;
    }
  }

  .product-description {
    max-width: 315px;
    width: 100%;

    .product-header {
      font-weight: bold;
      font-size: 18px;
      padding-bottom: 20px;
      margin-top: 40px;
    }

    p {
      line-height: 21px;
      font-size: 16px;
      color: $black;
    }
  }

  .title-wrapper h1 {
    margin: 30px 0 10px;
    text-align: left;
    color: $black;
    font-weight: bold;
    font-size: 30px;
  }

  .sku {
    color: #666666;
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 100;
  }
  
  .prices-wrapper {
    font-size: 20px;
    margin: 0 0 20px;

    .final-price,
    .initial-price {
      margin: 0 4px;
      display: block;

      @include breakpoint($desk) {
        display: inline-block;
      }  
    }

    .initial-price {
      color: #828282;
      -webkit-text-decoration-line: line-through;
      text-decoration-line: line-through;
    }
  } 

  .product-details {
    line-height: 22px;
    font-size: 16px;
    max-width: 300px;
  }

  .field--name-field-product-image {
    text-align: center;
    position: relative;

    img {
      width: 100%;
      display: block;
      height: auto;
      margin: 0 auto;

      @include breakpoint($tab) {
        max-width: 400px;

        @include breakpoint($desk) {
          max-width: 550px;
        }
      }
    }
  }

  .out-of-stock-overlay {
    position: relative;

     .field--name-field-product-image {
      text-align: center;
      position: relative;

      &:after {
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        display: block;
        background: rgba(255, 255, 255, 0.3);
        top: 0;
      }
    }

    .out-of-stock-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #000;
      z-index: 1;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .flex-control-nav {
    position: relative;
    bottom: 0;
    padding-top: 20px;

    @include breakpoint($tab) {
      display: none;
    }
  }

  .flex-direction-nav {
    display: none;

    @include breakpoint($tab) {
      display: block;
    }
  }

  .flex-direction-nav li {
    position: absolute;
    top: 50%;
  }

  .flex-nav-next {
    right: -8px;

    @include breakpoint($desk) {
      right: -5px;
    }
  }

  .flex-direction-nav .flex-prev {
    left: -10px;

    @include breakpoint($desk) {
      left: -25px;
    }
  }

  .flex-direction-nav .flex-next {
    right: 0;
  }

  .flex-direction-nav a {
    opacity: 1;
    font-size: 0;
  
    @include breakpoint($desk) {
      left: 21px;
    }
  }

  .flex-direction-nav li {
    width: 45px;
    height: 45px;
  }

  .flex-direction-nav .flex-prev {
    //@extend .icon-keyboard_arrow_left;
    @extend .font-icon;
    content: url('../svg/product-right-arrow.svg');
    transform: rotate(180deg);
    width: 45px;
    height: 45px;

  }

  .flex-direction-nav .flex-next {
    content: url('../svg/product-right-arrow.svg');
    @extend .font-icon;
  }

  .product-colors {
    label {
      font-size: 16px;
      margin-bottom: 20px;
      display: block;
      font-weight: 700;
    }

   li {
      border: 1px solid #000;
      margin-right: 10px;
      padding: 10px 20px;
      display: inline-block;
      margin-bottom: 0;
      cursor: pointer;
      background: transparent;

      a {
        font-weight: 700;
      }

      &.active {
        background: #000;
        color: #fff;
        
        a {
          color: #fff;
        }

        &:hover {
          background: #000;
          border: 1px solid #000;
        }
      }

      &:hover {
        border: 1px solid #cbcbcb;
        background: #cbcbcb;
        color: #fff;
      }
    }
  }

  .product-cart {
    margin-top: 30px;

    .form-item label {
      font-size: 16px;
      margin-bottom: 20px;
    }

    input[type="radio"] + div:before, input[type="radio"] + label:before {
      display: none;
      width: 0;
      height: 0;
    }


    input[type="radio"] + div:after, input[type="radio"] + label:after {
      display: none;
      width: 0;
      height: 0;
    }

    .form-radios .form-type-radio input[type="radio"] + label {
      display: inline-block;
      border: 1px solid $black;
      margin-right: 10px;
    }

    .form-radios .form-type-radio {
      display: inline-block;
      
      label.option {
        padding: 10px 20px;
        display: inline-block;
        margin-bottom: 0;
        cursor: pointer;
        font-weight: bold;
      }
    }

    .form-radios .form-type-radio.form-disabled {
      input[type="radio"] + label {
        color: $grey_icon;
        border: 1px solid $grey_icon;
      }
    }

    .form-radios .form-type-radio:hover label.option {
      cursor: pointer;
    }

    .form-radios .form-type-radio:hover {

      input[type="radio"] + label {
        border: 1px solid $background;
        // background: repeating-linear-gradient(45deg, #fff, #fff 0, #CCFBF5 15px, #fff 15px);
        background: $background;
        color: #fff;
      }

      input[type="radio"]:checked+label {
        border: 1px solid $black;
        background: #000;
        color: #fff;
      }
    }

    input[type="radio"]:checked+label {
      background: #000;
      color: #fff;
    }

    input[type="submit"] {
      cursor: pointer;
      border-radius: 0;
      background-image: url(../svg/submit-arrow-white.svg);
      background-repeat: no-repeat;
      background-position: 75% 50%;
      width: 100%;
      line-height: 43px;
      max-width: 300px;
      margin: 40px 0 25px;
      transition: all .2s ease-in-out;

      &:hover {
        background: #000;
        color: #fff;
        border: 1px solid #000;
        background-image: url(/sites/all/themes/custom/rocket_path_theme/svg/submit-arrow-white.svg);
        background-repeat: no-repeat;
        background-position: 85% 50%;
      }
    }

    .out-of-stock-submit-wrapper,
    .disabled-submit-wrapper {
      input[type="submit"] {
        cursor: unset;

        &:hover {
          // background: repeating-linear-gradient(45deg, #fff, #fff 0, #CCFBF5 15px, #fff 15px);
          background: $black;
          background-repeat: no-repeat;
          background-position: 75% 50%;
          background-image: url(../svg/submit-arrow-white.svg);
          color: #fff;
          border: 1px solid $black;
        }
      }
    }

    .form-item-quantity {
      label {
        display: none;
      }
      
      input[type="text"] {
        background: none;
        color: #000000;
        font-weight: bold;
        padding: 7px 0 10px 20px;
        font-size: 15px;
        line-height: 20px;
        border: 1px solid #000;
        width: 55px;
      }
    }
  }

  .product-wishlist {
    text-align: center;
    max-width: 300px;
    margin-bottom: 20px;

    a {
      font-size: 16px;
      color: $black;
      font-weight: bold;
    }
  }
}