a {
	color: $black;
	font-weight: bold;
}

.green-text {
	color: $green;
}

.red-text {
	color: $red;
}